<template>
    <el-input v-model="modelVal" readonly :placeholder="placeholder" :disabled="disabled"></el-input>
</template>

<script>
import ncformCommon from "@ncform/ncform-common";
import { format } from "date-fns";

export default {
    name: "SignDate",
    mixins: [
        ncformCommon.mixins.vue.controlMixin,
        ncformCommon.mixins.vue.layoutObjectMixin,
    ],
    props: {
        value: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            defaultConfig: {
                checked: false,
                format: "MM/dd/yyyy",
            },
        };
    },
    methods: {
        signToday() {
            this.modelVal = format(new Date(), this.mergeConfig.format);
        }
    },
    watch: {
        "mergeConfig.checked": {
            handler: function (n) {
                if (n) {
                    this.signToday();
                } else {
                    this.modelVal = null;
                }
            },
            immediate: true,
        },
    },
};
</script>
