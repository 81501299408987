import { ToastProgrammatic as Toast } from "buefy";

const globalFunction = {
  error: async function (msg) {
    Toast.open({
      duration: 4000,
      queue: true,
      message: msg,
      position: 'is-bottom-right',
      type: 'is-danger'
    });
    console.log("error notification:", msg);
  },
  notify: async function (
    type,
    msg,
    pos = "is-top-right",
    mod = false,
    container = "body"
  ) {
    // if (mod) {
    //   Notification.open({
    //     duration: 5000,
    //     message: '<span class="mdi mdi-file-edit-outline"></span>&nbsp;' + msg,
    //     position: pos,
    //     type: type,
    //     hasIcon: false,
    //     container: container,
    //   });
    // } else {
    //   Notification.open({
    //     duration: 5000,
    //     message: msg,
    //     position: pos,
    //     type: type,
    //     hasIcon: false,
    //     container: container,
    //   });
    // }
    console.log("notification:", msg);
  },
  /**
   * Return Capitalize First Letter
   * @param {String} string - Input string
   */
  cfl(string) {
    // capitalizeFirstLetter
    return string.charAt(0).toUpperCase() + string.slice(1);
  },
  /**
   * This is debounce funtion i.e it is called after sometime of input
   * @param {Function} function - which needs to excuteted @param {Number} - Time
   */
  debounce: async function (func, wait, immediate) {
    var timeout;

    return function executedFunction() {
      var context = this;
      var args = arguments;

      var later = function () {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };

      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);

      if (callNow) func.apply(context, args);
    };
  },
  randomNumGenerator(min, max) {
    console.log("ran", Math.floor(Math.random() * (max - min + 1) + min));
    return Math.floor(Math.random() * (max - min + 1) + min);
  },
  generateId: function (len) {
    var arr = new Uint8Array((len || 40) / 2);
    window.crypto.getRandomValues(arr);
    return Array.from(arr, (dec) => {
      return dec.toString(16).padStart(2, "0");
    }).join("");
  },

  fileHash: async function (file) {
    const arrayBuffer = await file.arrayBuffer();

    // Use the subtle crypto API to perform a SHA256 Sum of the file's Array Buffer
    // The resulting hash is stored in an array buffer
    const hashAsArrayBuffer = await window.crypto.subtle.digest(
      "SHA-256",
      arrayBuffer
    );

    // To display it as a string we will get the hexadecimal value of each byte of the array buffer
    // This gets us an array where each byte of the array buffer becomes one item in the array
    const uint8ViewOfHash = new Uint8Array(hashAsArrayBuffer);
    // We then convert it to a regular array so we can convert each item to hexadecimal strings
    // Where to characters of 0-9 or a-f represent a number between 0 and 16, containing 4 bits of information, so 2 of them is 8 bits (1 byte).
    const hashAsString = Array.from(uint8ViewOfHash)
      .map((b) => b.toString(16).padStart(2, "0"))
      .join("");
    return hashAsString;
  },
  trancate: async function (text, length, suffix) {
    if (text.length > length) {
      return text.substring(0, length) + suffix;
    } else {
      return text;
    }
  },
};

export { globalFunction };
