import axios from "axios";
import { sprintf } from "sprintf-js";
import { NotificationProgrammatic as Notification } from "buefy";

export class AuthFlow {
  constructor() {
    this.axiosInstance = axios.create({
      baseURL: "api"
    })
  }

  async isSignedIn() {
    try {
      let resp = await this.axiosInstance.get("/user/loggedin", {
        timeout: 50000,
      });
      if (resp.status !== 200) {
        this.promptError(
          sprintf(
            "GET /user/loggedin failed: %d: %s",
            resp.status,
            resp.data
          )
        );
        return false
      }
      return resp.data.status;
    } catch (error) {
      this.promptError(
        sprintf(
          "GET /user/loggedin failed: %s",
          error          
        )
      );
      return false
    }
  }

  async signOut() {
    try {
      let resp = await this.axiosInstance.get("/user/logout", {
        timeout: 50000,
      });
      if (resp.status !== 200) {
        this.promptError(
          sprintf(
            "GET /user/loggedin failed: %d: %s",
            resp.status,
            resp.data
          )
        );        
      }
    } catch (error) {
      this.promptError(
        sprintf(
          "GET /user/loggedin failed: %d: %s",
          error.response.status,
          error.response.data.error
        )
      );      
    }
  }
 
  async signIn(idToken) {
    try {
      let resp = await this.axiosInstance.get("/user/login", {
        headers: {
          Authorization: "Bearer " + idToken,
        },
        timeout: 50000,
      });
      if (resp.status !== 200) {
        this.promptError(
          sprintf(
            "GET /user/loggedin failed: %d: %s",
            resp.status,
            resp.data
          )
        );        
      }
    } catch (error) {
      this.promptError(
        sprintf(
          "GET /user/loggedin failed: %d: %s",
          error.response.status,
          error.response.data.error
        )
      );      
    }    
  }

  async getReq(apiUrl, params) {
    try {
      let resp = await this.axiosInstance.get(apiUrl, {
        params: params,
        timeout: 50000,
      });
      return resp;
    } catch (error) {
      console.log("getreq failed: for apiUrl", error);
      if (error.response) {
        if (error.response.data && error.response.data.error) {
          this.promptError(
            sprintf(
              "GET %s failed: %d: %s",
              apiUrl,
              error.response.status,
              error.response.data.error
            )
          );
        } else {
          this.promptError(
            sprintf("GET %s failed: %s", apiUrl, error.response.statusText)
          );
        }
      } else {
        this.promptError(sprintf("GET %s failed: %s", apiUrl, error));
      }
      return error;
    }
  }

  async postReq(apiUrl, data, params) {
    try {
      let resp = await this.axiosInstance.post(apiUrl, data, {
        params: params,
        timeout: 50000,
      });
      return resp;
    } catch (error) {
      console.log("postreq failed: for apiUrl", error);
      if (error.response) {
        if (error.response.data && error.response.data.error) {
          this.promptError(
            sprintf(
              "POST %s failed: %d: %s",
              apiUrl,
              error.response.status,
              error.response.data.error
            )
          );
        } else {
          this.promptError(
            sprintf("POST %s failed: %s", apiUrl, error.response.statusText)
          );
        }
      } else {
        this.promptError(sprintf("POST %s failed: %s", apiUrl, error));
      }

      return error;
    }
  }

  async delReq(apiUrl, params) {
    let resp = await this.axiosInstance.delete(apiUrl, {
      params: params,
      timeout: 50000,
    });

    return resp;
  }

  promptError(msg, duration = 5000) {
    // Notification.open({
    //   duration: duration,
    //   message: msg,
    //   position: "is-top-right",
    //   type: "is-qDanger",
    // });
    console.log(msg);
  }
}
