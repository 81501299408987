<template>
    <div>
        <label
            v-if="mergeConfig.fieldLabel"
            class="el-form-item__label"
            :title="mergeConfig.fieldLabel"
        >
            {{ mergeConfig.fieldLabel }}
            <el-tooltip
                class="item"
                effect="dark"
                :content="$parent.schema.ui.help.content"
                placement="right-start"
            >
                <div slot="content" v-html="$parent.schema.ui.help.content"></div>
                <a
                    class="help"
                    v-if="$parent.schema.ui.help.show === true"
                    href="#"
                    ><span :class="$parent.schema.ui.help.iconCls">{{
                        $parent.schema.ui.help.text
                    }}</span></a
                >
            </el-tooltip>
        </label>
        <span :class="['display-only', mergeConfig.multiLine ? 'multi-line' : '']">
            {{
                mergeConfig.fieldText ||
                _analyzeVal($parent.schema.value) ||
                _analyzeVal($parent.schema.default) ||
                $parent.htmlTypeVal
            }}
        </span>
    </div>
</template>
<style>
.display-only {
    font-size: 1rem;
    padding: 5px !important;
    display: block;
    clear: both;
}
.display-only:not(.multi-line) {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
</style>

<script>
import ncformCommon from "@ncform/ncform-common";
export default {
    mixins: [ncformCommon.mixins.vue.layoutObjectMixin],
    data() {
        return {
            defaultConfig: {
                fieldLabel: null,
                fieldText: null,
                multiLine: false
            },
        };
    },
};
</script>
