<template>
  <div class="dragit">
    <div>
      <b-modal v-model="reloadRequired" :width="640">
        <div class="card">
          <div class="card-content">
            <div class="content">
              An update is available. Please save all current work and click
              update below.<br />You can also accept these updates by refreshing
              your browser at any time. <br /><span class="has-text-danger is-size-7">Not updating may result in
                errors.</span>
            </div>
            <button class="button" @click="reloadApp">Update</button>
          </div>
        </div>
      </b-modal>
      <div v-if="!$route.meta.hideNavigation">
        <top-header :justHeader="false"
          hasStyle="padding: 10px;box-shadow: 5px 0px 13px 3px rgba(10, 10, 10, 0.1); background: white;">
        </top-header>
        <ProgressBar v-if="isLoadingBar" mode="indeterminate" style="height: 0.5em" />
        <div class="sidebar-page">
          <section class="sidebar-layout">
            <b-sidebar position="static" :mobile="mobile" :expand-on-hover="expandOnHover" :reduce="reduce"
              type="is-light" open>
              <div class="p-1">
                <b-menu class="is-custom-mobile">
                  <b-menu-list class="center">
                    <b-menu-item class="center" @click="routeTo('/home')" :active="isActive">
                      <template #label>
                        <QbIcon :name="'Home'" svgFile="ant-design_home-filled.svg" :currentURL="currentRoute" :id="'x1'"></QbIcon>                        
                      </template>

                    </b-menu-item>
                  </b-menu-list>

                  <b-menu-list>

                    <b-menu-item class="center" id="sidebar-item-6" @click="routeTo('/wallet')" expanded>
                      <template #label>
                        <QbIcon :name="'Wallet'" svgFile="wallet.svg" :currentURL="currentRoute"></QbIcon>
                      </template>
                    </b-menu-item>
                  </b-menu-list>

                  <b-menu-list>
                    <b-menu-item class="center" id="sidebar-item-2" @click="routeTo('/profile')" expanded>
                      <template #label>
                        <QbIcon :name="'Profile'" svgFile="Frame-_3_.svg" :currentURL="currentRoute"></QbIcon>                        
                      </template>

                    </b-menu-item>
                  </b-menu-list>

                  <b-menu-list>
                    <b-menu-item class="center" id="sidebar-item-4" @click="routeTo('/feedback')" expanded>
                      <template #label>
                        <QbIcon :name="'Feedback'" svgFile="flagquest.svg" :currentURL="currentRoute"></QbIcon>
                      </template>
                    </b-menu-item>
                  </b-menu-list>
                </b-menu>                
              </div>
            </b-sidebar>

            <div class="p-1 main-section" style="width: 100%">
              <!-- Main container -->

              <vue-page-transition name="fade">
                <router-view />
              </vue-page-transition>
            </div>
          </section>
        </div>
      </div>
      <div v-else>
        <vue-page-transition name="fade">
          <router-view />
        </vue-page-transition>
      </div>
    </div>
  </div>
</template>
<script>
import { refreshPageMixin } from "@/components/mixins/reload-page.mixin";
import ProgressBar from 'primevue/progressbar';
import QbIcon from "./components/qbIcon.vue";
export default {
  data() {
    return {
      expandOnHover: false,
      mobile: "reduce",
      reduce: true,
      userProfileImg: "",
      isActive: true,
      owner: "",
      logoSrc: null,
      window: {
        width: 0,
        height: 0,
      },
      api: null,
      appThis: this,
      isLoadingBar: false,
      currentRoute: ''
    };
  },
  mixins: [refreshPageMixin],
  computed: {
    hamvalue: {
      get() {
        return this.reduce;
      },
      set(value) {
        this.reduce = !value;
      },
    },
    reloadRequired: function () {
      return this.hashChanged && this.$root.env !== "development";
    },
    currentwoner: function () {
      // var vm = this;
      // `Object.assign` merges objects together to form a new object
      return Object.assign(
        {},
        // We add all the listeners from the parent
        this.$listeners,
        // Then we can add custom listeners or override the
        // behavior of some listeners.
        {
          // This ensures that the component works with v-model
          input: function (event) {
            console.log("event in input:", event);
          },
        }
      );
    },
    authFlowObject() {
      return this.$store.getters.authFlowObject;
    },
    operationIsLoading: {
      get: function () {
        return this.$store.getters.isLoading;
      },
      // setter
      set: function (newValue) {
        this.$store.commit("isLoading", newValue);
      },
    },
  },
  watch: {
    operationIsLoading: {
      handler(newValue, oldValue) {
        console.log("Loading Values", newValue, oldValue);
        if (newValue) {
          this.isLoadingBar = true;
        } else {
          this.isLoadingBar = false;
        }
        // this.slideup();
      },
      deep: true,
    },
  },
  components: {
    // sidebar: () => import("./components/sidebar"),
    // hamburgerMenu: () => import("./components/hamburger_menu"),
    topHeader: () => import("./components/pageHeader.vue"),
    ProgressBar,
    QbIcon,    
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  mounted() {
    this.initVersionCheck("/version.json");
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
      if (this.window.width < 1024) {
        this.logoSrc = "./assets/nobgblackbig.png";
      } else {
        this.logoSrc = "../public/horizontal_logo.png";
      }
    },
    logout: async function () {
      await this.authFlowObject.signOut();
      await this.$store.dispatch("USER_LOGOUT");
      localStorage.removeItem("userPhoto");
      this.$router.push("/");
    },
    routeTo: function (route) {
      this.currentRoute = route
      if (
        !this.$store.getters.authFlowObject ||
        !this.$store.getters.authFlowObject.isSignedIn()
      ) {
        console.log("redirecting to / for login");
        this.$router.push("/");
      }
      console.log("route to: ", route);
      console.log("current route: ", this.$route);
      if (this.$route.path === route) {
        console.log("ignoring same route switch");
        return;
      }
      switch (route) {
        case "/home":
          this.$router.push("/home");
          break;
        case "/status":
          this.$router.push("/status");
          break;
        case "/profile":
          this.$router.push("/profile");
          break;
        case "/feedback":
          this.$router.push("/feedback");
          break;
        case "/about":
          this.$router.push("/about");
          break;
        case "/verify":
          this.$router.push("/verify");
          break;
        case "/wallet":
          this.$router.push("/wallet");
          break;
        default:
          break;
      }
    },
    closeWindow: () => {
      // ipcRenderer.send("close_windows");
    },
    maximizeWindows: () => {
      // ipcRenderer.send("maximize_windows");
    },
  },
  events: {},
};
</script>
<style lang="scss">
@import "./styles/main.css";
@import "../node_modules/shepherd.js/dist/css/shepherd.css";
@import "./styles/main.scss";
@import "./styles/loadingdots.css";
@import "../node_modules/@fortawesome/fontawesome-free/css/all.css";
@import "../node_modules/bulma-extensions/bulma-tooltip/dist/css/bulma-tooltip.min.css";
@import "../node_modules/bulma-extensions/bulma-divider/dist/css/bulma-divider.min.css";
@import "../node_modules/bulma-extensions/bulma-timeline/dist/css/bulma-timeline.min.css";
@import "../node_modules/vue2-animate/dist/vue2-animate.min.css";

.logo-with-name {
  display: inline;
}

/*@media (min-width: 1024px) {
  .logo-with-name {
    display: none;
  }
}*/

.logo-without-name {
  display: none;
}

@media (min-width: 1024px) {
  .logo-without-name {
    display: inline;
  }
}

.window-frame-close:hover {
  color: red;
}

.window-frame-max:hover {
  color: darkgrey;
}

.window-frame-min:hover {
  color: darkgrey;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.refresh-container {
  width: 15%;
  position: fixed;
  bottom: 10px;
  right: 15px;
  background-color: $white;
  padding: 25px;
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.05);
}

.close-refresh-modal {
  position: absolute;
  right: 5px;
  top: 5px;
  border: none;
}
</style>
