import ncformCommon from "@ncform/ncform-common";
import {
    parse,
    differenceInCalendarDays,
    differenceInCalendarYears,
    differenceInCalendarWeeks,
    differenceInCalendarMonths,
} from "date-fns";

class CustomDateDiffRule extends ncformCommon.ValidationRule {
    constructor(props) {
        super(props);
        this.name = "dateDiffRule"; // your rule name
        this.defaultErrMsg = "Invalid date"; // you rule default error message tips
    }

    validateLogic(val, ruleVal) {
        // val: the input value for validating
        // ruleVal: rule's value. example: {maxLength: {value: 1}}, the ruleVal's value here is 1
        // You can change the error message programmatically by setting this.errMsg. (e.g. this.errMsg = 'There are some errors')
        // You can use this.$http to make some http request (this.$http is the same as axios)

        // Will always expect the value to be in MM/dd/yyyy format
        // Send value like 11Y or 14M or 400D that defines how the difference should be calculated as
        if (val && val !== "N/A") {
            const enteredDate = parse(val, "MM/dd/yyyy", new Date());
            const isDateFwd = !ruleVal.startsWith("-");
            ruleVal = ruleVal.toString().toUpperCase();
            const numVal = Number(ruleVal.replace(/\D/g, ""));
            if (isDateFwd) {
                if (ruleVal.endsWith("Y")) {
                    return differenceInCalendarYears(enteredDate, new Date()) >= numVal;
                } else if (ruleVal.endsWith("W")) {
                    return differenceInCalendarWeeks(enteredDate, new Date()) >= numVal;
                } else if (ruleVal.endsWith("M")) {
                    return differenceInCalendarMonths(enteredDate, new Date()) >= numVal;
                } else {
                    return differenceInCalendarDays(enteredDate, new Date()) >= numVal;
                }
            } else {
                if (ruleVal.endsWith("Y")) {
                    return differenceInCalendarYears(new Date(), enteredDate) >= numVal;
                } else if (ruleVal.endsWith("W")) {
                    return differenceInCalendarWeeks(new Date(), enteredDate) >= numVal;
                } else if (ruleVal.endsWith("M")) {
                    return differenceInCalendarMonths(new Date(), enteredDate) >= numVal;
                } else {
                    return differenceInCalendarDays(new Date(), enteredDate) >= numVal;
                }
            }
        }
        return true;
        // or return new Promise(resolve => resolve(true))
    }
}

export default CustomDateDiffRule;
