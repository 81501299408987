<template>
  <b-tooltip :label="name" position="is-right" type="is-dark"  size="is-large"  :active="notooltip">
    <div class="center">
      <svg :id="cid" :fill="svgColor"  style="width: 31px;
         height: 31px;">
        <use :xlink:href="`#${svgId}`" />
      </svg>
    </div>
  </b-tooltip>
</template>
  
<script>
import { isThisSecond } from 'date-fns';

export default {
  props: {
    svgFile: {
      type: String,
      required: true,
    },
    isActive: {
      type: Boolean,
      default: false
    },
    currentURL: {
      type: String,
      default: '/home'
    },
    cid: {
      type: String,
      default: 'x0'
    },
    name: {
      type: String,
      default: ""
    },
    notooltip: {
      type : Boolean,
      default: true
    }
  },
  data() {
    return {
      hover: false,
      svgId: '',
    };
  },
  mounted() {
    this.loadSvgFile();
  },
  watch: {
    currentURL(newVal, oldVal) {

      // this.urlChanged(newVal, oldVal)÷
    }
  },
  methods: {
    loadSvgFile(active = false) {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', this.getImgUrl(this.svgFile), true);
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4 && xhr.status === 200) {
          const parser = new DOMParser();
          const doc = parser.parseFromString(xhr.responseText, 'image/svg+xml');
          const svg = doc.documentElement;
          const symbol = document.createElementNS('http://www.w3.org/2000/svg', 'symbol');
          this.svgId = `svg-icon-${Math.random().toString(36).substr(2, 9)}`;
          symbol.setAttribute('id', this.svgId);
          console.log("SVG id", this.svgId)
          const svgElements = svg.querySelectorAll('g'); // Add other SVG elements as needed
          console.log("element", svgElements)
          console.log("ur", this.$route)

          const svgelement = document.getElementById(this.svgId)
          //     console.log("elementid",svgelement)
          //     svgElements.forEach((element,index) => {
          //     const originalFill = element.getAttribute('fill');
          //     if(active){
          //         element.setAttribute('fill', 'white');
          //     }else{
          //         element.setAttribute('fill', 'black');
          //     }

          //   });

          symbol.appendChild(svg);
          this.$el.querySelector('svg').appendChild(symbol);

        }
      };
      xhr.send();
    },
    urlChanged(newVal, oldVal) {
      // Perform actions based on the new and old values of myProp
      console.log('myProp changed:', newVal, oldVal, this.$route);
      if (newVal == this.$route.path) {
        this.changeColor(true)
      }
    },
    getImgUrl(img) {
      return require("../../src/assets/" + img);
    },
    changeColor(active = false) {
      const svgElements = document.querySelectorAll(`#${this.cid}`);
      // const elementsArray = Array.from(parentElement.querySelectorAll('#yourElementId'));

      // Print the array of elements

      console.log("selected svg", svgElements)
      if (this.currentURL == this.$route.path) {
        console.log("SD", this.currentURL, this.$route.path)
        svgElements.forEach((item, key) => {
          console.log("tag", item)
          let gtag = item.querySelectorAll('g')
          console.log("dss", gtag)
          gtag.forEach((item, key) => {
            item.setAttribute('fill', 'white');
          })

        })
      }
    }
  },
  computed: {
    svgColor() {
      return this.hover ? 'blue' : 'black';
    },
  },
};
</script>
  
<style scoped>
svg {
  transition: fill 0.3s;
}
</style>
  