<template>
    <el-select v-model="modelVal" :placeholder="placeholder" :disabled="disabled || onlyOne" :filterable="true">
        <el-option v-for="(item, idx) in options" :key="idx" :label="item" :value="item"></el-option>
    </el-select>
</template>

<script>
import ncformCommon from "@ncform/ncform-common";
export default {
    name: "DocTitleVerify",
    mixins: [ncformCommon.mixins.vue.controlMixin],
    props: {
        value: {
            type: String,
            default: "N/A",
        },
    },
    created() {
        if (this.value !== '') {
            this.modelVal = this.value;
        }
    },
    data() {
        return {
            defaultConfig: {
                list: "A",
                level: 0,
                superVal: null, // Set this to Level 0 value for List A on Level 1 and 2
                upperVal: null, // Set this to Level 1 value for List A on Level 2
                otherSelected: false,
                citizenStatus: null,
            },
            options: [],
            onlyOne: false,
        };
    },
    methods: {
        fillOptions() {
            const data = [];
            if (this.mergeConfig.list === "A") {
                if (this.mergeConfig.level == 0) {
                    this._genListA(data);
                } else if (this.mergeConfig.level == 1) {
                    this._genListA1(data);
                } else if (this.mergeConfig.level == 2) {
                    this._genListA2(data);
                }
            } else if (this.mergeConfig.list === "B") {
                this._genListB(data);
            } else if (this.mergeConfig.list === "C") {
                this._genListC(data);
            }
            this.options = data;
            if (data.length == 1) {
                this.modelVal = data[0];
                this.onlyOne = true;
            } else {
                this.onlyOne = false;                
            }
        },
        _genListA(data) {
            if (
                this.mergeConfig.citizenStatus === 1 ||
                this.mergeConfig.citizenStatus === 2
            ) {
                data.push("N/A", "U.S. Passport", "U.S. Passport Card");
            } else if (this.mergeConfig.citizenStatus === 3) {
                data.push(
                    "N/A",
                    "Perm. Resident Card (Form I-551)",
                    "Alien Reg. Receipt Card (Form I-551)",
                    "Foreign Passport with Temp. I-551 Stamp",
                    "Foreign Passport with Temp. I-551 MRIV",
                    "Receipt Form I-94/I-94A w/I-551 stamp, photo",
                    "Receipt Replacement Perm. Res. Card (Form I-551)"
                );
            } else if (this.mergeConfig.citizenStatus === 4) {
                data.push(
                    "N/A",
                    "Employment Auth. Document (Form I-766)",
                    "Foreign Passport, work-authorized nonimmigrant",
                    "FSM Passport with Form I-94",
                    "RMI Passport with Form I-94",
                    "Receipt Replacement EAD (Form I-766)",
                    "Receipt Form I-94/I-94A w/refugee stamp (or RE class of admission)",
                    "Receipt Foreign Passport, work-authorized nonimmigrant",
                    "Receipt FSM Passport with Form I-94",
                    "Receipt RMI Passport with Form I-94"
                );
            } else {
                data.push("N/A")
            }
        },
        _genListA1(data) {
            if (
                ["Foreign Passport with Temp. I-551 Stamp"].includes(
                    this.mergeConfig.superVal
                )
            ) {
                data.push("Temporary I-551 stamp");
            } else if (
                ["Foreign Passport with Temp. I-551 MRIV"].includes(
                    this.mergeConfig.superVal
                )
            ) {
                data.push("Machine-readable immigrant visa (MRIV)");
            } else if (
                [
                    "Foreign Passport, work-authorized nonimmigrant",
                    "FSM Passport with Form I-94",
                    "RMI Passport with Form I-94",
                    "Receipt Foreign Passport, work-authorized nonimmigrant",
                    "Receipt FSM Passport with Form I-94",
                    "Receipt RMI Passport with Form I-94",
                ].includes(this.mergeConfig.superVal)
            ) {
                data.push("Form I-94/I-94A", "Receipt Form I-94/I-94A");
            } else {
                data.push("N/A");
            }
        },
        _genListA2(data) {
            if (["Foreign Passport, work-authorized nonimmigrant", "Receipt Foreign Passport, work-authorized nonimmigrant", "Receipt FSM Passport with Form I-94", "Receipt RMI Passport with Form I-94"].includes(
                this.mergeConfig.superVal
            )) {
                data.push("N/A", "Form I-20", "Form DS-2019");
            } else {
                data.push("N/A");
            }
        },
        _genListB(data) {
            // So far there is no difference based on citizenship status
            data.push(
                "N/A",
                "Driver's license issued by state/territory",
                "ID card issued by state/territory",
                "Government ID card",
                "School ID card",
                "Voter's registration card",
                "U.S. military card",
                "U.S. military draft record",
                "Military dependent's ID card",
                "USCG Merchant Mariner card",
                "Native American tribal document",
                "Canadian driver's license",
                "School record (under age 18)",
                "Report card (under age 18)",
                "Clinic record (under age 18)",
                "Doctor record (under age 18)",
                "Hospital record (under age 18)",
                "Day-care record (under age 18)",
                "Nursery school record (under age 18)",
                "Individual under age 18",
                "Special Placement",
                "Receipt replacement driver's license",
                "Receipt replacement ID card",
                "Receipt replacement government ID card",
                "Receipt replacement school ID card",
                "Receipt replacement voter reg. card",
                "Receipt replacement U.S. military card",
                "Receipt replacement military draft record",
                "Receipt replacement military dep. card",
                "Receipt replacement USCG Merchant Mariner card",
                "Receipt replacement Native American tribal document",
                "Receipt replacement Canadian DL",
                "Receipt replacement school record (under age 18)",
                "Receipt replacement report card (under age 18)",
                "Receipt replacement clinic record (under age 18)",
                "Receipt replacement doctor  record (under age 18)",
                "Receipt replacement hospital record (under age 18)",
                "Receipt replacement day-dare record (under age 18)",
                "Receipt replacement nursery school record (under age 18)"
            );
        },
        _genListC(data) {
            if (
                this.mergeConfig.citizenStatus === 1 ||
                this.mergeConfig.citizenStatus === 2
            ) {
                data.push(
                    "N/A",
                    "Social Security card (unrestricted)",
                    "Form FS-545",
                    "Form DS-1350",
                    "Form FS-240",
                    "U.S. birth certificate",
                    "Native American tribal document",
                    "Form I-197",
                    "Form I-179",
                    "Employment auth. document (DHS)",
                    "Receipt replacement SS card (unrestricted)",
                    "Receipt replacement U.S. birth certificate",
                    "Receipt replace. Native American tribal document",
                    "Receipt replace. employment auth. doc. (DHS)"
                );
            } else if (this.mergeConfig.citizenStatus === 3) {
                data.push(
                    "N/A",
                    "Social Security card (unrestricted)",
                    "Native American tribal document",
                    "Employment auth. document (DHS)",
                    "Receipt replace. Native American tribal document",
                    "Receipt replacement SS card (unrestricted)",
                    "Receipt replace. employment auth. doc. (DHS)"
                );
            } else {
                data.push(
                    "N/A",
                    "Social Security card (unrestricted)",
                    "Native American tribal document",
                    "Employment auth. document (DHS)",
                    "Receipt replacement SS card (unrestricted)",                    
                );
            }
        },
    },
    watch: {
        "mergeConfig.otherSelected": {
            handler: function (n) {      
                if (n) {
                    this.modelVal = "N/A";
                } else if (this.modelVal === 'N/A') {
                    this.modelVal = null;
                }
            },
            immediate: true,
        },
        "mergeConfig.citizenStatus": {
            handler: function (n) {
                if (n && !this.disabled) {
                    this.fillOptions();
                }
            },
            immediate: true,
        },
        "mergeConfig.superVal": {
            handler: function () {
                if (this.mergeConfig.list === "A" && !this.disabled) {
                    this.fillOptions();
                    if (this.options.length > 1 && this.modelVal === 'N/A') {
                        this.modelVal = null;
                    }
                }
            },
            immediate: true,
        },
        "mergeConfig.upperVal": {
            handler: function () {
                if (this.mergeConfig.list === "A" && !this.disabled) {
                    this.fillOptions();
                    if (this.options.length > 1 && this.modelVal === 'N/A') {
                        this.modelVal = null;
                    }
                }
            },
            immediate: true,
        },
    },
};
</script>
