<template>
    <el-input
        v-model="inputVal"
        :disabled="disabled"
        :readonly="readonly"
        :placeholder="placeholder"
        v-show="!hidden"
        :clearable="mergeConfig.clearable"
        @blur="onBlur">
    </el-input>
</template>

<script>
import ncformCommon from "@ncform/ncform-common";
import toUpper from "lodash/toUpper";

export default {
    name: "I9StringAttest",
    mixins: [
        ncformCommon.mixins.vue.controlMixin,
        ncformCommon.mixins.vue.layoutObjectMixin,
    ],
    props: {
        value: {
            type: String,
            default: null,
        },
    },
    created() {
        if (this.$data.modelVal !== undefined) {
            this.$data.inputVal = this.$data.modelVal;
        }
    },
    data() {
        return {
            defaultConfig: {
                otherChecked: false,
                forceNA: false
            },
            inputVal: "",
        };
    },
    methods: {
        _processModelVal(newVal) {
            let val;
            if (newVal !== undefined) {
                val = toUpper(newVal);
            } else if (this.mergeConfig.forceNA) {
                val = "N/A"
            } else {
                val = toUpper(this.$data.inputVal);
            }            
            this.$data.inputVal = val;
            return val;
        },
        onBlur() {
            let val = this._processModelVal();
            this.$emit("input", val);
        }
    },
    watch: {
        "mergeConfig.otherChecked": {
            handler: function (n) {
                if (n) {
                    this.$emit("input", "N/A");
                }                
            },
            immediate: true,
        },
    },
};
</script>
