<template>
    <div>
        <div class="signature__panel">
            <el-image
                :disabled="disabled"
                :src="imageLoading ? '' : tempVal"
                style="width: 150px; height: 100px"
                :loading="imageLoading">
                <div slot="error" class="placeholder">Please sign</div>
            </el-image>
            <el-button-group v-if="!mergeConfig.signedDate">
                <el-button
                    :disabled="disabled"
                    :type="imageLoading ? 'warning' : ''"
                    icon="el-icon-edit"
                    @click="showDialog = true">
                    Click to sign
                </el-button>
                <el-button                    
                    @click="confirmSignature"
                    type="success"
                    icon="el-icon-check"
                    :disabled="imageLoading || disabled">
                    Confirm
                </el-button>
            </el-button-group>
        </div>
        <el-dialog
            class="normal__dialog"
            :title="
                $parent.schema.label
                    ? $parent.schema.label + ' Panel'
                    : 'Signature Panel'
            "
            :visible.sync="showDialog"
            :disabled="disabled"
            width="520px"
        >
            <div class="signature__panel_display">
                <el-alert
                    title="Guideline"
                    type="info"
                    description="You have been provided with a scribble pad to mark your signature. Kindly use the mouse or if your display supports touch then use a stencil while scribbling for better results."
                    show-icon
                    :closable="false"
                    :disabled="disabled"
                >
                </el-alert>
                <div style="padding: 5px">
                    <VueSignaturePad
                        width="450px"
                        height="300px"
                        ref="signaturePad"
                        :style="{
                            border: '2px solid black !important',
                        }"
                        :disabled="disabled"
                    />
                </div>
            </div>
            <div slot="footer">
                <el-button icon="el-icon-refresh-left" @click="resetPanel" :disabled="disabled">
                    Reset
                </el-button>
                <el-button @click="showDialog = false" :disabled="disabled">Cancel</el-button>
                <el-button
                    type="primary"
                    icon="el-icon-check"
                    @click="savePanel" :disabled="disabled">
                    Save
                </el-button>
            </div>
        </el-dialog>
    </div>
</template>

<style>
.signature__panel {
    display: flex;
    align-items: center;
    width: 100%;
}
.signature__panel .placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    height: 100%;
    color: red;
}
.signature__panel .el-button-group {
    margin-left: 10px !important;
}
.signature__panel .el-button {
    padding: 8px 5px !important;
}
.signature__panel_display {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
}
</style>

<script>
import ncformCommon from "@ncform/ncform-common";
import isEmpty from "lodash/isEmpty";

export default {
    name: "SignaturePanel",
    mixins: [ncformCommon.mixins.vue.controlMixin],
    props: {
        value: {
            type: String,
            default: '',
        },
    },
    computed: {
        imageLoading() {
            return isEmpty(this.tempVal);
        }
    },
    created() {
        this.tempVal = this.modelVal;
    },
    data() {
        return {
            defaultConfig: {
                signedDate: null, // Expects date in
            },
            tempVal: '',
            showDialog: false,
        };
    },
    methods: {
        resetPanel() {
            this.$refs.signaturePad.clearSignature();
        },
        savePanel() {
            const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
            if (!isEmpty) {
                this.tempVal = data;
                this.showDialog = false;
            }
        },
        confirmSignature() {
            this.$emit("input", this.tempVal);
        }
    },
};
</script>
