<template>
    <div class="full-w">
        <el-select v-model="modelVal" :placeholder="placeholder" v-show="!hidden" size="large"
            :disabled="disabled || readonly" :allow-create="mergeConfig.canCreate" :clearable="true" :multiple="false"
            :filterable="true" v-if="canSelect">
            <el-option v-for="item in options" :key="item.id" :label="item.code" :value="item.code">
            </el-option>
        </el-select>
        <el-input v-if="!canSelect" :size="mergeConfig.size" :disabled="disabled" :readonly="readonly"
            :placeholder="placeholder" v-show="!hidden" :clearable="mergeConfig.clearable" type="text"
            v-model="modelVal">
        </el-input>
    </div>
</template>

<script>
import ncformCommon from "@ncform/ncform-common";


export default {
    name: "UsStateCityZip",
    mixins: [ncformCommon.mixins.vue.controlMixin],
    props: {
        value: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            defaultConfig: {
                multiple: false,
                clearable: true,
                canCreate: false,
                filterable: true,
                filterLocal: true,
                commuter: false,
                mode: "STATE", // STATE or CITY or ZIP
                linkedState: null, // Selected state value
                linkedCity: null, // Selected city value
            },
            options: [],
            canSelect: true,
        };
    },
    created() {
        if (this.value !== '') {
            this.modelVal = this.value;
        }
    },
    methods: {
        _generateStates() {
            let list = [];
            for (const d of this.$store.getters.usStateData) {
                list.push({
                    id: d.id,
                    code: d.stateCode
                })
            }
            if (this.mergeConfig.commuter) {
                list.push({ id: 100, code: "CAN" });
                list.push({ id: 101, code: "MEX" });
            }
            this.options = list;
            this.canSelect = true;
        },
        _generateCities(state) {
            let list = [];
            const defState = this.$store.getters.usStateData.find((d) => d.stateCode === state);
            if (defState && defState.cities && defState.cities.length > 0) {
                for (const c of defState.cities) {
                    list.push({
                        id: c.id,
                        code: c.city
                    })
                }
                this.canSelect = true;
            } else {
                this.canSelect = false;
            }
            this.options = list;
            // If only one value then preselect
            if (list.length == 1) {
                this.modelVal = list[0].code;
            }
        },
        _generateZip(state, city) {
            let list = [];
            const defState = this.$store.getters.usStateData.find((d) => d.stateCode === state);
            if (defState && defState.cities && defState.cities.length > 0) {
                const defCity = defState.cities.find((d) => d.city === city);
                if (defCity && defCity.zip && defCity.zip.length > 0) {
                    for (const c of defCity.zip) {
                        list.push({
                            id: c,
                            code: c.toString()
                        })
                    }
                    this.canSelect = true;
                } else {
                    this.canSelect = false;
                }
            } else {
                this.canSelect = false;
            }
            this.options = list;
            // If only one value then preselect
            if (list.length == 1) {
                this.modelVal = list[0].code;
            }
        }
    },
    watch: {
        "mergeConfig.mode": {
            handler: function (n) {
                if (n === "STATE" && !this.disabled) {
                    this._generateStates();
                }
            },
            immediate: true
        },
        "mergeConfig.linkedState": {
            handler: function (n) {
                if (this.mergeConfig.mode === "CITY" && n && !this.disabled) {
                    this._generateCities(n)
                    const foundCity = this.options.find((o) => o.code === this.modelVal);
                    if (!foundCity) {
                        this.modelVal = null;
                    }
                }
            },
            immediate: true
        },
        "mergeConfig.linkedCity": {
            handler: function (n) {
                if (this.mergeConfig.mode === "ZIP" && n && !this.disabled) {
                    this._generateZip(this.mergeConfig.linkedState, n)
                    const foundZip = this.options.find((o) => o.code === this.modelVal);
                    if (!foundZip) {
                        this.modelVal = null;
                    }
                }
            },
            immediate: true
        }
    }
};
</script>
