<template>
  <div>
    <!-- <div class="card-container">
      <top-header :justHeader="true"></top-header>
    </div> -->
    <div class="custom-container center-imp">
      <img class="rotate home-background" src="../../public/logowhitebiggrey.png" />
      <div>
        <div class="columns">
          <div class="column">
            <div class="has-text-centered">
              <img alt="Vue logo" class="logo-img" src="../assets/nobgblackbig.png" />
              <div class="\">
                <h1 class="title is-1 has-text-centered logofont">
                  Qbrics Flows
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column center-imp">
            <transition name="bounce" enter-active-class="bounceInLeft" leave-active-class="bounceOutRight">
              <div style="width: 300px">
                <button id="my-signinM" class="button is-fullwidth" @click="signInWithMicrosoft">
                  <span class="icon">
                    <img alt="Vue logo" style="height: 20px" src="../assets/microsoft.png" />
                  </span>
                  <span>Continue with Microsoft</span>
                </button>
                <!-- Popup for the profile input -->
                <b-modal :active.sync="isProfile" :can-cancel="false">
                  <profile-sign></profile-sign>
                </b-modal>
              </div>
            </transition>
          </div>
        </div>
        <div class="columns">
          <div class="column center-imp">
            <transition name="bounce" enter-active-class="bounceInLeft" leave-active-class="bounceOutRight">
              <div>
                <div style="display: flex; justify-content: center" id="google-login-btn" v-google-identity-login-btn="{
                  clientId: clientId,
                  locale: 'en',
                }">
                  Continue With Google
                </div>
                <!-- Popup for the profile input -->
                <b-modal :active.sync="isProfile" :can-cancel="popupProp">
                  <profile-sign></profile-sign>
                </b-modal>
              </div>
            </transition>
          </div>
        </div>
        <div class="columns">
          <div class="column center-imp">
            <p>
              By clicking Continue, you agree to our
              <a @click="showTnc = true">Terms</a>
              and that you
              <br />have read our <a @click="showPp = true">Privacy Policy</a>.
            </p>
            <!-- Popup for the Tnc -->
            <b-modal :active.sync="showTnc" :can-cancel="popupProp">
              <tnc-modal></tnc-modal>
            </b-modal>
            <!-- Popup for the Privacy policy -->
            <b-modal :active.sync="showPp" :can-cancel="popupProp">
              <pp-modal></pp-modal>
            </b-modal>
          </div>
        </div>
        <b-loading :is-full-page="isFullPage" :active.sync="isLoading"></b-loading>
      </div>
    </div>
  </div>
</template>

<script>
import { endpoint } from "../services/global-constant";
import Vue from "vue";
import VueSignaturePad from "vue-signature-pad";
import GoogleSignInButton from "vue-google-identity-login-btn";
import axios from "axios";

Vue.use(VueSignaturePad);

export default {
  directives: {
    GoogleSignInButton,
  },
  data() {
    return {
      animated: true,
      isSigning: false,
      isMSigning: false,
      acceptSigning: false,
      googleUser: {},
      auth2: null,
      isProfile: false,
      showTnc: false,
      showPp: false,
      isLoading: true,
      isFullPage: true,
      signinDisclamer: false,
      signinMDisclamer: false,
      organizationSigninDisclamer: false,
      organizationSigninMDisclamer: false,
      popupProp: ["escape", "x"],
      popupPropM: ["escape", "x"],
      fullName: "",
      phone: "",
      address: "",
      designation: "",
      registered: null,
      customNavigation: true,
      api: null,
      usersubs: null,
      serverBaseURL: "",
      clientId:
        "618144833883-t6akc81io2k9mcq1q6jt3daeavvnm2vv.apps.googleusercontent.com",
    };
  },
  watch: {},
  components: {
    topHeader: () => import("../components/pageHeader.vue"),
    profileSign: () => import("../components/profileSign.vue"),
    tncModal: () => import("../components/tncModal.vue"),
    ppModal: () => import("../components/ppModal.vue"),
  },
  beforeRouteLeave(to, from, next) {
    this.$destroy();
    next();
  },
  beforeDestroy() {
    if (this.usersubs) {
      this.usersubs.unsubscribe();
      this.usersubs.removeAllListeners();
      this.usersubs = null;
    }
  },
  mounted() {
    this.init();
  },
  computed: {
    authFlowObject() {
      return this.$store.getters.authFlowObject;
    },
    centrifugo() {
      return this.$store.getters.centrifugoObject;
    },
  },
  methods: {
    init: async function () {
      this.isLoading = true;
      this.serverBaseURL = "https://flows.qbrics.com";
      this.$store.dispatch("INIT_AUTHOBJECT").then(() => {
        var url = window.location.origin;
        console.log("origin:", url);
        this.$store
          .dispatch("INIT_CENTRIFUGO", {
            isProduction: url == this.serverBaseURL,
          })
          .then(() => {
            this.$store.dispatch("INIT_STATICDATA").then(() => {
              this.$store.getters.authFlowObject.isSignedIn().then((status) => {
                if (status) {
                  this.getUserDetails().then(() => {
                    this.isLoading = false;
                  });
                } else {
                  this.isLoading = false;
                }
              });
            });
          });
      });
    },
    async onGoogleAuthSuccess(idToken) {
      console.log("received google credentials");
      await this.authFlowObject.signIn(idToken);
      await this.getUserDetails();
      const profileData = JSON.parse(Buffer.from(idToken.split('.')[1], 'base64'));
      const { name, picture, email } = profileData;
      console.log("picture:", picture);
      try {
        var resp = await axios.get(picture, {
          responseType: 'arraybuffer',
        });
        var prefix = "data:" + resp.headers["content-type"] + ";base64,";
        var data = Buffer.from(resp.data, 'binary').toString('base64');
        var dataUri = prefix + data;
        console.log('photo data:', dataUri);
        localStorage.setItem('userPhoto', dataUri);
        this.$root.$emit('user-photo');
        await this.authFlowObject.postReq(
          '/user/photo',
          { data: dataUri },
          null
        );
      } catch (error) {
        console.log("failed to get user profile photo:", error);
      }
    },
    undo: async function () {
      this.$refs.signaturePad.undoSignature();
    },
    clear: async function () {
      this.$refs.signaturePad.clearSignature();
    },
    save: async function () {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      console.log(isEmpty);
      console.log(data);
    },

    getUserDetails: async function () {
      var userDetails;
      userDetails = await this.authFlowObject.getReq(
        endpoint.USER_DETAILS,
        null
      );

      console.log("Console log : userDetails", userDetails);

      await this.$store.dispatch("USER_DETAILS", userDetails.data);

      if (!userDetails.data.registered && !userDetails.data.profile_available) {
        this.registered = false;
        await this.regUser();
        this.isProfile = true;
        this.isLoading = false;
      } else if (
        userDetails.data.registered &&
        !userDetails.data.profile_available
      ) {
        this.isLoading = false;
        this.isProfile = true;
      } else {
        this.isLoading = false;
        this.$router.push("/home");
      }
    },
    setProfile: async function () {
      var setProfileReq = {
        full_name: this.fullName,
        phone: this.phone,
        address: this.address,
        designation: this.designation,
      };
      var setProfile = await this.authFlowObject.postReq(
        endpoint.USER_PROFILE,
        setProfileReq,
        null
      );
      console.log("Console log : setProfile", setProfile);

      if (
        this.registered &&
        (setProfile.status == 200 || setProfile.status == 201)
      ) {
        this.$router.push("/home");
      }
    },
    signInWithMicrosoft: async function () {
      try {
        this.isLoading = true;
        let token = await this.$msal.authenticatePopup();
        if (token !== null) {
          await this.authFlowObject.signIn(token.idToken);
          await this.getUserDetails();
        } else {
          console.log("no token from authenticate!!");
        }
        var bearer = "Bearer " + token.accessToken;

        try {
          var resp = await axios.get("https://graph.microsoft.com/v1.0/me/photo/$value", {
            headers: {
              Authorization: bearer,
            },
            responseType: 'arraybuffer',
          });
          var prefix = "data:" + resp.headers["content-type"] + ";base64,";
          var data = Buffer.from(resp.data, 'binary').toString('base64');
          var dataUri = prefix + data;
          console.log('photo data:', dataUri);
          localStorage.setItem('userPhoto', dataUri);
          this.$root.$emit('user-photo');
          await this.authFlowObject.postReq(
            '/user/photo',
            { data: dataUri },
            null
          );
        } catch (error) {
          console.log("failed to get user profile photo:", error);
        }
      } catch (error) {
        this.isLoading = false;
        console.log("error with microsoft login:", error);
      }
    },
    regUser: async function () {
      let response = await this.authFlowObject.postReq(
        endpoint.USER_REG,
        null,
        null
      );

      if (response.status == 200 || response.status == 201) {
        this.registered = true;
        console.log("successfully registered the user");
        this.centrifugo.setToken(response.data.token);
        if (!this.usersubs) {
          this.usersubs = this.centrifugo.subscribe(
            "user#" + response.data.uuid,
            (message) => {
              // console.log(message);
              // this.$emit("signed", sign);
              console.log(
                "%c %c Console Log message ",
                message,
                "background: #222; color: #FEDE00"
              );
            }
          );
        }
        this.centrifugo.connect();
      } else {
        // ipcRenderer.invoke("register-fail", true);
        console.log("failed to reister user " + response);
      }
    },
    cancelSignin: function () {
      console.log("cancelling signin");
      this.isSigning = false;
    },
  },
};
</script>
<style type="text/css">
#customBtn {
  display: inline-block;
  background: white;
  color: #444;
  width: 190px;
  border-radius: 5px;
  border: thin solid #888;
  box-shadow: 1px 1px 1px grey;
  white-space: nowrap;
}

.rotate {
  position: absolute;
  left: -36em;
  top: -135px;
  opacity: 0.1;
  height: 98vh;
  width: 139vh;
  animation: rotation 25s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

#customBtn:hover {
  cursor: pointer;
}

span.label {
  font-family: serif;
  font-weight: normal;
}

.custom-container {
  height: calc(100vh - 220px);
  width: 100%;
}

/* span.icon {
  background: url("/identity/sign-in/g-normal.png") transparent 5px 50%
    no-repeat;
  display: inline-block;
  vertical-align: middle;
  width: 42px;
  height: 42px;
} */
span.buttonText {
  display: inline-block;
  vertical-align: middle;
  padding-left: 42px;
  padding-right: 42px;
  font-size: 14px;
  font-weight: bold;
  /* Use the Roboto font that is loaded in the <head> */
  font-family: "Roboto", sans-serif;
}

.logo-img {
  height: 126px;
  width: 182px;
}

@media (max-width: 900px) {
  .logo-img {
    height: 126px;
    width: 182px;
  }
}
</style>
