import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Buefy from "buefy";
import vueOptionEvents from "vue-option-events";
import VuePageTransition from "vue-page-transition";
import vueNcform from "@ncform/ncform";
import Element from "element-ui";
import locale from "element-ui/lib/locale/lang/en";
import "element-ui/lib/theme-chalk/index.css";
import "./styles/app.css";
import ncformStdComps from "@ncform/ncform-theme-elementui";
import VueScrollTo from "vue-scrollto";
import "@ncform/ncform-common";

import CitizenType from "./components/CitizenType.vue";
import CountryList from "./components/CountryList.vue";
import DisplayOnly from "./components/DisplayOnly.vue";
import DocTitleVerify from "./components/DocTitleVerify.vue";
import GenQrCode from "./components/GenQrCode.vue";
import I9BooleanAttest from "./components/I9BooleanAttest.vue";
import I9DocNumberAttest from "./components/I9DocNumberAttest.vue";
import I9NumberTypeAttest from "./components/I9NumberTypeAttest.vue";
import I9StringAttest from "./components/I9StringAttest.vue";
import IssuingAuthVerify from "./components/IssuingAuthVerify.vue";
import LabelTooltip from "./components/LabelTooltip.vue";
import SectionDataDisplay from "./components/SectionDataDisplay.vue";
import SignaturePanel from "./components/SignaturePanel.vue";
import SignDate from "./components/SignDate.vue";
import USStateCityZip from "./components/USStateCityZip.vue";
import CustomDateDiffRule from "./services/CustomDateDiffRule";
import ConditionalString from "./components/ConditionalString";
import PrimeVue from "primevue/config";
import VueSignaturePad from "vue-signature-pad";
import moment from "moment";
import Vuelidate from "vuelidate";
import { default as msalPlugin } from "vue-msal-browser";
import VueSplit from "vue-split-panel";
const msalConfig = {
  auth: {
    clientId: "d9b70744-c31c-4d08-92ce-c1a6a1cde635",
    scopes: ["openid", "profile", "email", "User.Read"],
  },
};
import VueResource from "vue-resource";

Vue.use(msalPlugin, msalConfig);
Vue.use(vueOptionEvents, {
  keepAlive: true,
});

Vue.use(VueSplit);

Vue.use(PrimeVue, { ripple: true });
Vue.use(VueResource);
Vue.use(Vuelidate);
Vue.use(VueSignaturePad);
Vue.use(VueScrollTo);
Vue.use(VuePageTransition);
Vue.use(Buefy);
Vue.use(Element, { locale });
Vue.use(vueNcform, {
  extComponents: Object.assign(ncformStdComps, {
    CitizenType,
    USStateCityZip,
    I9BooleanAttest,
    I9StringAttest,
    I9NumberTypeAttest,
    I9DocNumberAttest,
    CountryList,
    SignDate,
    DocTitleVerify,
    IssuingAuthVerify,
    SignaturePanel,
    GenQrCode,
    LabelTooltip,
    DisplayOnly,
    SectionDataDisplay,
    ConditionalString,    
  }),
  extRules: [
    {
      dateDiffRule: CustomDateDiffRule,
    },
  ],
  lang: "en",
});

Vue.component("gen-qr-code", GenQrCode);
Vue.component("label-tooltip", LabelTooltip);
Vue.component("display-only", DisplayOnly);
Vue.component("section-data-display", SectionDataDisplay);

Vue.filter("standard_time", function (value) {
  if (!value) return new Date();

  return moment().format(value, "MMMM Do YYYY, h:mm:ss a");
});

Vue.filter("remaining_time", function (value) {
  if (value == "NA") return "NA";
  const parsed = moment.utc(value, "DD MMM YYYY hh:mm:ss UTC");
  const remaining = moment.duration(parsed.diff());
  const remainingTime =
    remaining.days().toString() +
    "Day(s) " +
    remaining.hours().toString() +
    "Hour(s)";
  return remainingTime;
});

Vue.filter("remaining_time_precise", function (value) {
  
  if (value == "NA") return "NA";
  const parsed = moment.utc(value, "DD MMM YYYY hh:mm:ss UTC");
  const remaining = moment.duration(parsed.diff());
  let remainingTime = "";
  if(remaining.days() > 0){
    remainingTime =
    remaining.days().toString() +
    "Day(s) Left" ;
       
    return remainingTime
  }else if (remaining.hours() > 0){
   
    remainingTime =
    remaining.hours().toString() +
    "Hour(s) Left";
  return remainingTime;
  } else {
    remainingTime = remaining.minutes().toString() + "Minute(s) Left";
    return remainingTime;
  }
   
});
Vue.filter("time_elapsed", function (value) {
  if (value == "NA") return "NA";
  const parsed = moment.utc(value, "DD MMM YYYY hh:mm:ss UTC");
  const elapsed = moment.duration(moment().diff(parsed));
  const elapsedTime =
    elapsed.days().toString() +
    "Day(s) " +
    elapsed.hours().toString() +
    "Hour(s)";
  return elapsedTime;
});

Vue.filter("time_elapsed_age", function (value) {
  if (value == "NA") return "NA";
  const parsed = moment.utc(value, "DD MMM YYYY hh:mm:ss UTC");
  const elapsed = moment.duration(moment().diff(parsed));
  var elapsedTime = "";
  if (elapsed.years() > 1) {
    elapsedTime = elapsedTime + elapsed.years().toString() + " Years ";
  } else if (elapsed.years() > 0) {
    elapsedTime = elapsedTime + elapsed.years().toString() + " Year ";
  }
  if (elapsed.months() > 1) {
    elapsedTime = elapsedTime + elapsed.months().toString() + " Months ";
  } else if (elapsed.months() > 0) {
    elapsedTime = elapsedTime + elapsed.months().toString() + " Month ";
  }
  if (elapsed.days() > 1) {
    elapsedTime = elapsedTime + elapsed.days().toString() + " Days ";
  } else if (elapsed.days() > 0) {
    elapsedTime = elapsedTime + elapsed.days().toString() + " Day ";
  }
  if (elapsed.hours() > 1) {
    elapsedTime = elapsedTime + elapsed.hours().toString() + " Hours ";
  } else if (elapsed.hours() > 0) {
    elapsedTime = elapsedTime + elapsed.hours().toString() + " Hour ";
  }
  if (elapsedTime === "") {
    if (elapsed.minutes() > 5) {
      elapsedTime = elapsedTime + elapsed.minutes().toString() + " Minutes ";
    } else {
      elapsedTime = "Few Minutes";
    }
  }
  return elapsedTime;
});

Vue.filter("time_elapsed_presice", function (value) {
  if (value == "NA") return "NA";
  const parsed = moment.utc(value, "DD MMM YYYY hh:mm:ss UTC");
  const elapsed = moment.duration(moment().diff(parsed));
  let finalTime = " ";
  if (elapsed.days().toString() != "0") {
    finalTime = finalTime + elapsed.days().toString() + "d ";
  }
  if (elapsed.hours().toString() != "0") {
    finalTime = finalTime + elapsed.hours().toString() + "h ";
  }
  if (elapsed.minutes().toString() != "0") {
    finalTime = finalTime + elapsed.minutes().toString() + "m ";
  }
  if (elapsed.seconds().toString() != "0") {
    finalTime = finalTime + elapsed.seconds().toString() + "s ";
  }
  // const elapsedTime = elapsed.days().toString()+"Day(s) " + elapsed.hours().toString()+"Hour(s)"
  return finalTime;
});

Vue.filter("time_humanized", function (value) {
  if (value == "NA") return "NA";
  const parsed = moment.utc(value, "DD MMM YYYY hh:mm:ss UTC");
  return moment.duration(-moment().diff(parsed)).humanize(true);
});

Vue.filter("calendar_time", function (value) {
  if (value == "NA") return "NA";
  const parsed = moment.utc(value, "DD MMM YYYY hh:mm:ss UTC");
  return parsed.local().calendar();
});

Vue.filter("title_case", function (str) {
  if (str == "NA") return str;
  return str
    .toLowerCase()
    .split(" ")
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
});

Vue.filter("humanize_string", function (str) {
  if (!str) return "NA";
  return str.replaceAll("_", " ");
});

Vue.filter('truncate', function (text, length, suffix) {
  if (text.length > length) {
    return text.substring(0, length) + suffix;
  } else {
    return text;
  }
});

Vue.filter('relativeTime', function(value) {
  const time = moment(value);
  const now = moment();
  const diffMinutes = now.diff(time, 'minutes');
  const diffHours = now.diff(time, 'hours');
  const diffDays = now.diff(time, 'days');

  if (diffMinutes < 1) {
    return 'Just now';
  } else if (diffHours < 1) {
    return `${diffMinutes} min${diffMinutes > 1 ? 's' : ''} ago`;
  } else if (diffDays < 1) {
    return `${diffHours} hr${diffHours > 1 ? 's' : ''} ago`;
  } else if (diffDays < 7) {
    return `${diffDays} day${diffDays > 1 ? 's' : ''} ago`;
  } else {
    return time.format('MMM Do YYYY');
  }
});

Vue.filter('usNumberFormat', function(value) {
  if (!value) return '0';
  
  // Convert the value to a string and remove any existing commas
  let formattedValue = value.toString().replace(/,/g, '');
  
  // Split the string into integer and decimal parts (if any)
  const parts = formattedValue.split('.');
  
  // Add commas to the integer part
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  
  // Join the integer and decimal parts back together
  formattedValue = parts.join('.');
  
  return formattedValue;
});

Vue.config.productionTip = false;

export const eventBus = new Vue(); 

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
