<template>
    <el-select
        v-model="modelVal"
        :placeholder="placeholder"
        v-show="!hidden"
        size="large"
        :disabled="disabled || readonly"
        :clearable="true"
        :multiple="false"
        :filterable="true">
        <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.name"
            :value="item.name">
        </el-option>
    </el-select>
</template>

<script>
import ncformCommon from "@ncform/ncform-common";


export default {
    name: "CountryList",
    mixins: [ncformCommon.mixins.vue.controlMixin],
    props: {
        value: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            defaultConfig: {
                multiple: false,
                clearable: true,
                filterable: true,
                filterLocal: true,
                reset: false
            },
            options: this.$store.getters.countryList
        };
    },
    watch: {
        "mergeConfig.reset": {
            handler: function(n) {                
                if (n === 'N/A') {
                    this.modelVal = 'N/A';
                } else if (this.modelVal === 'N/A') {
                    this.modelVal = null;
                }
            },
            immediate: true
        }
    }
};
</script>
