<template>
<el-row align="middle" v-if="mergeConfig.details && mergeConfig.details.length">
    <el-col :span="c.span" v-for="(c, idx) in mergeConfig.details" :key="idx" class="all-border">
        <div class="linear-container" v-if="c.labelOnly">
            <span role="label">{{ c.label }}</span>
            <el-tooltip class="item linear-container-popper" effect="dark" placement="right-start" v-if="c.helpContent">
                <div slot="content" v-html="c.helpContent"></div>
                <a class="help" href="#"><span>?</span></a>
            </el-tooltip>
        </div>
        <div v-else>
            <label class="el-form-item__label" :title="c.label" >
                {{ c.label }}
                <el-tooltip class="item linear-container-popper" effect="dark" placement="right-start" v-if="c.helpContent">
                    <div slot="content" v-html="c.helpContent"></div>
                    <a class="help" href="#"><span>?</span></a>
                </el-tooltip>
            </label>
            <span :class="['display-only', c.multiLine ? 'multi-line' : '']">{{_analyzeVal(c.value)}}</span>
        </div>
    </el-col>
</el-row>
</template>

<script>
/**
 * Expects a generic object structure like this in details
 * { span: number, label: string, value?: anything that can resolved via dx expression, helpContent?: string, labelOnly?: boolean, multiLine?: boolean }
 */
import ncformCommon from "@ncform/ncform-common";
export default {
    mixins: [ncformCommon.mixins.vue.layoutObjectMixin],
    data() {
        return {
            defaultConfig: {
                details: [],
            },
        };
    }
};
</script>
