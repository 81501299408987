<template>
    <div class="linear-container">
        <span role="label">{{ _analyzeVal($parent.schema.ui.label) }}</span>
        <el-tooltip
            class="item linear-container-popper"
            effect="dark"
            placement="right-start"
        >
            <div slot="content" v-html="$parent.schema.ui.help.content"></div>
            <a class="help" v-if="$parent.schema.ui.help.show === true" href="#"
                ><span :class="$parent.schema.ui.help.iconCls">{{
                    $parent.schema.ui.help.text
                }}</span>
            </a>
        </el-tooltip>
    </div>
</template>

<script>
import ncformCommon from "@ncform/ncform-common";
export default {
    mixins: [ncformCommon.mixins.vue.layoutObjectMixin],
};
</script>
