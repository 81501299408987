<template>
<div class="qr-code-section">
    <div class="qr-header" v-if="mergeConfig.headerContent" v-html="mergeConfig.headerContent"></div>
    <qrcode-vue :value="qrValue" :size="size" level="H" />
    <div class="qr-footer" v-if="mergeConfig.footerContent" v-html="mergeConfig.footerContent"></div>
</div>
</template>

<script>
import ncformCommon from "@ncform/ncform-common";
import QrcodeVue from "qrcode.vue";
export default {
    mixins: [ncformCommon.mixins.vue.controlMixin],
    data() {
        return {
            size: 150,
            defaultConfig: {
                encodeValue: [],
                headerContent: null,
                footerContent: null
            }            
        };
    },
    components: {
        QrcodeVue
    },
    computed: {
    qrValue() {      
      return this.mergeConfig.encodeValue.join(",");
    },
  },
};
</script>
<style scoped>
.qr-code-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.qr-code-section .qr-header,
.qr-code-section .qr-footer {
    padding: 20px 0 !important;
    text-align: center !important;
}
</style>
