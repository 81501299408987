<template>
    <el-input v-model="inputVal" :disabled="disabled" :readonly="readonly || isNA"
        :placeholder="placeholder" v-show="!hidden" :clearable="mergeConfig.clearable" @blur="onBlur">
        <template slot="prepend" v-if="showSlot">A</template>
    </el-input>
</template>

<script>
import ncformCommon from "@ncform/ncform-common";
import toUpper from "lodash/toUpper";
import startsWith from "lodash/startsWith";

export default {
    name: "I9DocNumberAttest",
    mixins: [
        ncformCommon.mixins.vue.controlMixin,
        ncformCommon.mixins.vue.layoutObjectMixin,
    ],
    props: {
        value: {
            type: String,
            default: null,
        },
    },
    created() {
        if (this.$data.modelVal !== undefined) {
            this.$data.inputVal = this.$data.modelVal;
        }
    },
    data() {
        return {
            defaultConfig: {
                otherChecked: false,
                forceNA: false,
                docType: null,
            },
            inputVal: "",
        };
    },
    computed: {
        isAlien() {
            return startsWith(this.mergeConfig.docType, "Alien");
        },
        showSlot() {
            return this.isAlien && !startsWith(this.$data.inputVal, "A");
        },
        isNA() {
            return this.mergeConfig.docType === "N/A";
        },
    },
    methods: {
        _processModelVal(newVal) {
            let val;
            if (newVal !== undefined) {
                val = toUpper(newVal);
            } else if (this.mergeConfig.forceNA) {
                val = "N/A"
            } else {
                val = toUpper(this.$data.inputVal);
            }            
            this.$data.inputVal = val;
            return val;
        },
        onBlur() {
            let val = this._processModelVal();
            this.$emit("input", val);
        }
    },
    watch: {
        "mergeConfig.otherChecked": {
            handler: function (n) {                
                if (n) {         
                    this.$emit("input", "N/A");
                }
            },
            immediate: true,
        },
        "mergeConfig.docType": {
            handler: function (n) {           
                if (n === 'N/A') {
                    this.$emit("input", "N/A");
                } else if(this.inputVal === 'N/A') {
                    this.$emit("input", null);
                }
            },
            immediate: true,
        }
    },
};
</script>
<style lang="scss">

/* Inline #8 | https://flows.qbrics.com/#/home */

.__ncform-control {
  /* width: 100%; */
  /* display: inline-block; */
  display: inline-table !important;
}

</style>
