import store from "../store";

import { globalFunction } from "./global.function";
import unset from "lodash/unset";
import set from "lodash/set";
import get from "lodash/get";

export class glAPI {
  constructor(forCreatePopup = false) {
    this.forCreatePopup = forCreatePopup;
  }

  async setSubmitAction(action) {
    try {
      store.commit("submitAction", action);
    } catch (error) {
      console.log("failed to set submit action:", error);
    }
  }

  async setSuccessMsg(msg) {
    try {
      store.commit("successMsg", msg);
    } catch (error) {
      console.log("failed to set success message:", error);
    }
  }

  async setFormDataFromAPI(form, dest, src, params, path) {
    try {
      store.commit("glBusy", true);
      const formDataBefore = store.getters["formData"](form) || {};
      set(formDataBefore, dest, "updating...");
      store.dispatch("FORM_DATA", {
        form_name: form,
        form_data: formDataBefore,
      });
      let response = await store.getters["authFlowObject"].getReq(src, params);
      const value = get(response.data, path);
      console.log("setting ", path, " to value", value, " from API ", src);
      const formData = store.getters["formData"](form) || {};
      set(formData, dest, value);
      store.dispatch("FORM_DATA", { form_name: form, form_data: formData });
      store.commit("glBusy", false);
      return Promise.resolve(true);
    } catch (error) {
      console.log("failed to set form data:", error);
      store.commit("glBusy", false);
      return Promise.resolve(false);
    }
  }

  async setFormConstantFromPath(form, name, src, path) {
    try {
      store.commit("glBusy", true);
      const formData = store.getters["formData"](src);
      const value = get(formData, path);
      store.commit("updateFormConstant", {
        form: form,
        name: name,
        value: value,
      });
      store.commit("glBusy", false);
      return Promise.resolve(true);
    } catch (error) {
      console.log("failed to set form constant:", error);
      store.commit("glBusy", false);
      return Promise.resolve(false);
    }
  }

  async setFormConstant(form, name, value) {
    try {
      store.commit("glBusy", true);
      store.commit("updateFormConstant", {
        form: form,
        name: name,
        value: value,
      });
      store.commit("glBusy", false);
      return Promise.resolve(true);
    } catch (error) {
      console.log("failed to set form constant:", error);
      store.commit("glBusy", false);
      return Promise.resolve(false);
    }
  }

  async setProcessCache(form, name, src, path) {
    try {
      store.commit("glBusy", true);
      let formData = store.getters["formData"](src);
      let value = get(formData, path, null);
      store.commit("updateFormConstant", {
        form: form,
        name: name,
        value: value,
      });
      if (value !== null) {
        let response = await store.getters["authFlowObject"].postReq(
          "/workflow/" + store.getters["getWorkFlowID"] + "/cache",
          {
            form: form,
            key: name,
            value: value,
          }
        );
        if (response.status === 200) {
          store.commit("glBusy", false);
          console.log("set process cache successful");
          return Promise.resolve(true);
        }
      }
      store.commit("glBusy", false);
      return Promise.resolve(true);
    } catch (error) {
      console.log("failed to set process cache:", error);
      store.commit("glBusy", false);
      return Promise.resolve(false);
    }
  }

  async unsetFormData(name, paths) {
    try {
      store.commit("glBusy", true);
      let formData = store.getters["formData"](name);
      for (let index = 0; index < paths.length; index++) {
        const path = paths[index];
        if (path === "*") {
          await this.persistFormData(name, formData, true);
          store.commit("formData", { form_name: name, form_data: null });
          store.commit("glBusy", false);
          return Promise.resolve(true);
        }
        if (!unset(formData, path)) {
          console.log("unset failed for form:", name, "path:", path);
        }
      }
      await this.persistFormData(name, formData);
      store.commit("formData", { form_name: name, form_data: formData });
      store.commit("glBusy", false);
      return Promise.resolve(true);
    } catch (error) {
      console.log("failed to unset form data:", error);
      store.commit("glBusy", false);
      return Promise.resolve(false);
    }
  }

  async switchWorkflow(target) {
    try {
      store.commit("glBusy", true);
      await this.persistState();
      let response = await store.getters["authFlowObject"].postReq(
        "/bundle/" + store.getters["getWorkFlowID"],
        {
          workflow_name: target,
        }
      );
      if (response.status === 200) {
        store.commit("glBusy", false);
        console.log("switch workflow successful");
        return Promise.resolve(true);
      }
      store.commit("glBusy", false);
      return Promise.resolve(false);
    } catch (error) {
      await this.persistState();
      console.log("failed to switch workflow:", error);
      store.commit("glBusy", false);
      return Promise.resolve(false);
    }
  }

  async createInstance(data) {
    try {
      store.commit("glBusy", true);
      const fn = store.getters["currentFormName"];
      const fd = store.getters["formData"](fn);
      await this.persistFormData(fn, fd);
      const resp = await store.getters["authFlowObject"].postReq(
        "/template/" + data.name + "/create",
        {
          name: data.name,
          roles: data.roles,
          parent_id: data.link ? store.getters["getWorkFlowID"] : "",
        }
      );
      if (resp.status === 200) {
        console.log("successfully created instance: ", resp);
        if (!this.forCreatePopup) {
          await this.persistState();
        }
      } else {
        if (!this.forCreatePopup) {
          await this.persistState();
        }
      }
      store.commit("glBusy", false);
    } catch (error) {
      console.log("failed to create instance:", error);
      if (!this.forCreatePopup) {
        await this.persistState();
      }
      store.commit("glBusy", false);
    }
  }

  async persistState() {
    if (!store.getters["getWorkFlowID"]) {
      return;
    }
    const alreadyBusy = store.getters["glBusy"];
    try {
      store.commit("glBusy", alreadyBusy || true);
      const resp = await store.getters["authFlowObject"].postReq(
        "/workflow/" + store.getters["getWorkFlowID"] + "/transition",
        {
          persisted_state: store.getters["machine"].state,
        }
      );
      if (resp.status === 200) {
        console.log("state transition persisted successfully");
      }
      store.commit("glBusy", alreadyBusy || false);
    } catch (error) {
      console.log("state transition could not be persisted:", error);
      store.commit("glBusy", alreadyBusy || false);
    }
  }

  async persistFormData(name, data, deleteData = false) {
    const alreadyBusy = store.getters["glBusy"];
    try {
      store.commit("glBusy", alreadyBusy || true);
      const resp = await store.getters["authFlowObject"].postReq(
        "/workflow/" + store.getters["getWorkFlowID"] + "/formdata",
        {
          form_data: data,
          form_name: name,
          delete_form_data: deleteData,
        }
      );
      if (resp.status === 200) {
        console.log("form data persisted successfully");
      }
      store.commit("glBusy", alreadyBusy || false);
    } catch (error) {
      console.log("form data could not be persisted:", error);
      store.commit("glBusy", alreadyBusy || false);
    }
  }

  async createCertificate() {
    try {
      store.commit("glBusy", true);
      store.commit("skipAction", true);
      const wid = store.getters["getWorkFlowID"];
      const sid = store.getters["sessionID"];
      console.log("wid:", wid, "sid:", sid);
      window.open("/certificate?wid=" + wid + "&sid=" + sid, "_blank");
      const resp = await store.getters["authFlowObject"].postReq(
        "/user/sessionid",
        { sid: sid }
      );
      if (resp.status !== 200) {
        store.dispatch("NOTIFY_OUTCOME", false);
        console.log("failed to push session id:", resp);
      } else {
        store.dispatch("NOTIFY_OUTCOME", true);
      }
      store.commit("sessionID", globalFunction.generateId());
      console.log("new session id:", store.getters["sessionID"]);
      store.commit("glBusy", false);
      store.commit("isLoading", false);
      return Promise.resolve();
    } catch (error) {
      console.log("create certificate error:", error);
      globalFunction.notify("is-danger", "certificate generation failed");
      store.commit("glBusy", false);
      return Promise.reject();
    }
  }

  async createToken() {
    try {
      store.commit("glBusy", true);
      store.commit("skipAction", true);
      const wid = store.getters["getWorkFlowID"];
      const resp = await store.getters["authFlowObject"].postReq(
        "/workflow/" + wid + "/tokenize",
        {}
      );
      if (resp.status !== 200) {
        console.log("failed to create token:", resp);
        store.dispatch("NOTIFY_OUTCOME", false);
      } else {
        store.dispatch("NOTIFY_OUTCOME", true);
      }
      store.commit("glBusy", false);
      store.commit("isLoading", false);
      return Promise.resolve();
    } catch (error) {
      console.log("create token error:", error);
      globalFunction.notify("is-danger", "token generation failed");
      store.commit("glBusy", false);
      return Promise.reject();
    }
  }

  async burnCoins(data) {
    try {
      store.commit("glBusy", true);
      const resp = await store.getters["authFlowObject"].postReq("/coin/burn", {
        coin: data.coin,
        amount: data.amount,
        user: data.user || "",
        wid: data.wid || "",
        role: data.role || "",
      });
      if (resp.status !== 200) {
        console.log("failed to burn coins:", resp);        
      }
      store.commit("glBusy", false);
      store.commit("isLoading", false);
      return Promise.resolve();
    } catch (error) {
      console.log("create token error:", error);
      globalFunction.notify("is-danger", "token generation failed");
      store.commit("glBusy", false);
      return Promise.reject();
    }
  }

  async transferCoins(data) {
    try {
      store.commit("glBusy", true);
      const resp = await store.getters["authFlowObject"].postReq(
        "/coin/transfer",
        {
          coin: data.coin,
          amount: data.amount,
          recipient: data.recipient,
        }
      );
      if (resp.status !== 200) {
        console.log("failed to transfer coins:", resp);        
      }
      store.commit("glBusy", false);
      store.commit("isLoading", false);
      return Promise.resolve();
    } catch (error) {
      console.log("create token error:", error);
      globalFunction.notify("is-danger", "token generation failed");
      store.commit("glBusy", false);
      return Promise.reject();
    }
  }

  async mintCoins(data) {
    try {
      store.commit("glBusy", true);
      const resp = await store.getters["authFlowObject"].postReq("/coin/mint", {
        coin: data.coin,
        amount: data.amount,
        user: data.user || "",
        wid: data.wid || "",
        role: data.role || "",
      });
      if (resp.status !== 200) {
        console.log("failed to mint coins:", resp);        
      }
      store.commit("glBusy", false);
      store.commit("isLoading", false);
      return Promise.resolve();
    } catch (error) {
      console.log("create token error:", error);
      globalFunction.notify("is-danger", "token generation failed");
      store.commit("glBusy", false);
      return Promise.reject();
    }
  }

  async mintActionCoins(data) {
    try {
      store.commit("glBusy", true);
      store.commit("skipAction", true);
      const resp = await store.getters["authFlowObject"].postReq("/user/mint", {
        recipient: data.recipient,
        amount: data.amount,
      });
      if (resp.status !== 200) {
        console.log("failed to mint action coins:", resp);
        store.dispatch("NOTIFY_OUTCOME", false);
      } else {
        store.dispatch("NOTIFY_OUTCOME", true);
      }
      store.commit("glBusy", false);
      store.commit("isLoading", false);
      return Promise.resolve();
    } catch (error) {
      console.log("create token error:", error);
      globalFunction.notify("is-danger", "token generation failed");
      store.commit("glBusy", false);
      return Promise.reject();
    }
  }

  async updateGroup(data) {
    try {
      store.commit("glBusy", true);
      const resp = await store.getters["authFlowObject"].postReq("/groups", {
        name: data.name,
        add: data.add,
        remove: data.remove,
      });
      if (resp.status === 200) {
        console.log("successfully updated group");
        await this.persistState();
      } else {
        await this.persistState();
      }
      store.commit("glBusy", false);
    } catch (error) {
      console.log("failed to update group:", error);
      await this.persistState();
      store.commit("glBusy", false);
    }
  }

  async deleteInstance() {
    try {
      store.commit("glBusy", true);
      const resp = await store.getters["authFlowObject"].delReq(
        "/workflow/" + store.getters["getWorkFlowID"],
        null
      );
      if (resp.status === 200) {
        console.log("successfully deleted instance");
        await store.dispatch("CLEAR_WORKFLOW");
      } else {
        console.log("unexpected response on delete instance:", resp);
      }
      store.commit("glBusy", false);
    } catch (error) {
      console.log("failed to delete instance:", error);
      store.commit("glBusy", false);
    }
  }

  async setOrgData(payload) {
    try {
      store.commit("glBusy", true);
      const resp = await store.getters["authFlowObject"].postReq(
        "/orgdata",
        payload
      );
      if (resp.status === 200) {
        console.log("successfully updated org data");
      } else {
        console.log("unexpected response on set org data:", resp);
      }
      store.commit("glBusy", false);
    } catch (error) {
      console.log("failed to set org data:", error);
      store.commit("glBusy", false);
    }
  }

  async sendEmail(to, template) {
    try {
      store.commit("glBusy", true);
      const resp = await store.getters["authFlowObject"].postReq(
        "/user/email",
        {
          to: to,
          template: template,
        }
      );
      if (resp.status === 200) {
        console.log("email sent successfully");
      } else {
        console.log("failed to send email:", resp);
      }
      store.commit("glBusy", false);
    } catch (error) {
      console.log("failed to send email:", error);
      store.commit("glBusy", false);
    }
  }
}
