<template></template>

<script>
/**
 * This component is specifically used to calculate the citizenship type value
 */
import ncformCommon from "@ncform/ncform-common";

export default {
    name: "CitizenType",
    mixins: [ncformCommon.mixins.vue.controlMixin],
    data() {
        return {
            defaultConfig: {
                evalObj: null,
            },
        };
    },
    watch: {
        "mergeConfig.evalObj": {
            handler: function (n) {                
                if (n) {
                    if (n["usCitizen"]) {
                        this.modelVal = 1;
                    } else if (n["usNonCitizen"]) {
                        this.modelVal = 2;
                    } else if (n["permResident"]) {
                        this.modelVal = 3;
                    } else if (n["otherResident"]) {
                        this.modelVal = 4;
                    } else {
                        this.modelVal = null;
                    }
                } else {
                    this.modelVal = null;
                }
            },
            immediate: true,
            deep: true,
        },
    },
};
</script>
