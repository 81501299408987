<template>
    <el-select v-model="modelVal" :placeholder="placeholder" :disabled="disabled || (onlyOne && !canCreate)"
        :filterable="true" :allow-create="canCreate" :default-first-option="canCreate" :no-data-text="noDataText">
        <el-option v-for="(item, idx) in options" :key="idx" :label="item" :value="item"></el-option>
    </el-select>
</template>

<script>
// WIP Component
import ncformCommon from "@ncform/ncform-common";

import map from "lodash/map";

export default {
    name: "IssuingAuthVerify",
    mixins: [ncformCommon.mixins.vue.controlMixin],
    props: {
        value: {
            type: String,
            default: null,
        },
    },
    created() {
        if (this.value !== '') {
            this.modelVal = this.value;
        }
    },    
    data() {
        return {
            defaultConfig: {
                list: "A",
                level: 0,
                docTitle: null
            },
            options: [],
            onlyOne: false,
            canCreate: false,
            noDataText: 'Enter text in space above',
        };
    },
    methods: {
        fillOptions() {
            const data = [];
            this.canCreate = false;
            if (this.mergeConfig.list === "A") {
                if (this.mergeConfig.level == 0) {
                    this._genListA(data);
                } else if (this.mergeConfig.level == 1) {
                    this._genListA1(data);
                } else if (this.mergeConfig.level == 2) {
                    this._genListA2(data);
                }
            } else if (this.mergeConfig.list === "B") {
                this._genListB(data);
            } else if (this.mergeConfig.list === "C") {
                this._genListC(data);
            }
            this.options = data;
            if (data.length == 1) {
                this.modelVal = data[0];
                this.onlyOne = true;
            } else {
                this.onlyOne = false;
                // this.modelVal = null;
            }
        },
        _genListA(data) {
            if (["U.S. Passport", "U.S. Passport Card"].includes(this.mergeConfig.docTitle)) {
                data.push("U.S. Department of State")
            } else if (["Perm. Resident Card (Form I-551)"].includes(this.mergeConfig.docTitle)) {
                data.push("U.S. Citizenship and Immigration Services", "DOJ Immigration and Naturalization Service")
            } else if (["Alien Reg. Receipt Card (Form I-551)"].includes(this.mergeConfig.docTitle)) {
                data.push("DOJ Immigration and Naturalization Service")
            } else if (["Foreign Passport with Temp. I-551 Stamp", "Foreign Passport with Temp. I-551 MRIV", "Foreign Passport, work-authorized nonimmigrant", "Receipt Foreign Passport, work-authorized nonimmigrant"].includes(this.mergeConfig.docTitle)) {
                data.push(...map(this.$store.getters.countryList, "name").sort());
            } else if (["Receipt Form I-94/I-94A w/I-551 stamp, photo"].includes(this.mergeConfig.docTitle)) {
                data.push("U.S. Department of Homeland Security")
            } else if (["Receipt Replacement Perm. Res. Card (Form I-551)", "Employment Auth. Document (Form I-766)"].includes(this.mergeConfig.docTitle)) {
                data.push("U.S. Citizenship and Immigration Services")
            } else if (["FSM Passport with Form I-94", "Receipt FSM Passport with Form I-94"].includes(this.mergeConfig.docTitle)) {
                data.push("Federated States of Micronesia")
            } else if (["RMI Passport with Form I-94", "Receipt RMI Passport with Form I-94"].includes(this.mergeConfig.docTitle)) {
                data.push("Republic of the Marshall Islands")
            } else if (["Receipt Replacement EAD (Form I-766)"].includes(this.mergeConfig.docTitle)) {
                data.push("U.S. Citizenship and Immigration Services")
            } else if (["Receipt Form I-94/I-94A w/refugee stamp (or RE class of admission)"].includes(this.mergeConfig.docTitle)) {
                data.push("U.S. Department of Homeland Security")
            } else {
                data.push("N/A");
            }
        },
        _genListA1(data) {
            if (["Temporary I-551 stamp"].includes(this.mergeConfig.docTitle)) {
                data.push("U.S. Citizenship and Immigration Services", "DOJ Immigration and Naturalization Service")
            } else if (["Machine-readable immigrant visa (MRIV)"].includes(this.mergeConfig.docTitle)) {
                data.push("U.S. Department of State")
            } else if (["Form I-94/I-94A", "Receipt Form I-94/I-94A"].includes(this.mergeConfig.docTitle)) {
                data.push("U.S. Citizenship and Immigration Services", "U.S. Customs and Border Protection")
            } else {
                data.push("N/A")
            }
        },
        _genListA2(data) {
            if (["Form I-20"].includes(this.mergeConfig.docTitle)) {
                data.push("U.S. Immigration and Customs Enforcement", "U.S. DOJ INS")
            } else if (["Form DS-2019"].includes(this.mergeConfig.docTitle)) {
                data.push("U.S. Department of State")
            } else {
                data.push("N/A")
            }
        },
        _genListB(data) {
            if (["Driver's license issued by state/territory", "ID card issued by state/territory", "Receipt replacement driver's license", "Receipt replacement ID card"].includes(this.mergeConfig.docTitle)) {
                data.push(...map(this.$store.getters.usStateData, "name").sort());
            } else if (["Government ID card",
                "School ID card",
                "Voter's registration card",
                "U.S. military card",
                "U.S. military draft record",
                "Native American tribal document",
                "School record (under age 18)",
                "Report card (under age 18)",
                "Clinic record (under age 18)",
                "Doctor record (under age 18)",
                "Hospital record (under age 18)",
                "Day-care record (under age 18)",
                "Nursery school record (under age 18)",
                "Receipt replacement government ID card",
                "Receipt replacement school ID card",
                "Receipt replacement voter reg. card",
                "Receipt replacement U.S. military card",
                "Receipt replacement military draft record",
                "Receipt replacement military dep. card",
                "Receipt replacement Native American tribal document",
                "Receipt replacement school record (under age 18)",
                "Receipt replacement report card (under age 18)",
                "Receipt replacement clinic record (under age 18)",
                "Receipt replacement doctor  record (under age 18)",
                "Receipt replacement hospital record (under age 18)",
                "Receipt replacement day-dare record (under age 18)",
                "Receipt replacement nursery school record (under age 18)",
                "Military dependent's ID card"].includes(this.mergeConfig.docTitle)) {
                this.canCreate = true

            } else if (["USCG Merchant Mariner card", "Receipt replacement USCG Merchant Mariner card"].includes(this.mergeConfig.docTitle)) {
                data.push("U.S. Coast Guard")
            } else if (["Canadian driver's license", "Receipt replacement Canadian DL"].includes(this.mergeConfig.docTitle)) {
                data.push("Alberta", "British Columbia", "Manitoba", "New Brunswick", "Newfoundland and Labrador", "Northwest Territories", "Nova Scotia", "Nunavut", "Ontario", "Prince Edward Island", "Quebec", "Saskatchewan", "Yukon")
            } else {
                data.push("N/A")
            }
        },
        _genListC(data) {
            if (["Social Security card (unrestricted)"].includes(this.mergeConfig.docTitle)) {
                data.push("Social Security Administration", "U.S. Department of Health and Human Services", "Social Security Board", "Department of Health, Education and Welfare");
            } else if (["Form FS-545", "Form DS-1350", "Form FS-240"].includes(this.mergeConfig.docTitle)) {
                data.push("U.S. Department of State")
            } else if (["U.S. birth certificate", "Native American tribal document", "Receipt replacement U.S. birth certificate", "Receipt replace. Native American tribal document"].includes(this.mergeConfig.docTitle)) {
                this.canCreate = true
            } else if (["Form I-197", "Form I-179"].includes(this.mergeConfig.docTitle)) {
                data.push("DOJ Immigration and Naturalization Service")
            } else if (["Employment auth. document (DHS)", "Receipt replace. employment auth. doc. (DHS)"].includes(this.mergeConfig.docTitle)) {
                data.push("DHS")
            } else if (["Receipt replacement SS card (unrestricted)"]) {
                data.push("Social Security Administration")
            } else {
                data.push("N/A")
            }
        },
    },
    watch: {
        "mergeConfig.docTitle": {
            handler: function (n) {
                if (n === "N/A") {
                    this.options = ["N/A"];
                    this.onlyOne = true;
                    this.canCreate = false;
                    this.modelVal = "N/A";
                } else {
                    this.fillOptions()
                    if (this.options.includes("N/A")) {
                        this.modelVal = "N/A";
                    } else if (this.modelVal === "N/A" || !this.options.includes(this.modelVal) ) {
                        this.modelVal = null;
                    }
                }
            },
            immediate: true,
        }
    },
};
</script>
