import object from "./object.vue";
import array from "./array.vue";
import arrayTable from "./array-table.vue";
// Don't touch me - import

module.exports = {
  object,
  array,
  arrayTable
  // Don't touch me - exports
};
