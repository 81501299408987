import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import moment from "moment";
import { AuthFlow } from "../services/auth.service";
import { createMachine, interpret, State } from "xstate";
import { glAPI } from "../services/glapi";
import Centrifuge from "centrifuge";
import { globalFunction } from "../services/global.function";
import get from "lodash/get";
import set from "lodash/set";
import { ToastProgrammatic as Toast } from "buefy";

Vue.use(Vuex);

const updateForm = (obj, disable) => {
  const type = obj.type || "";
  switch (type) {
    case "object":
      for (var p in obj.properties) {
        const value = obj.properties[p];
        const subType = value.type || "";
        switch (subType) {
          case "object":
            updateForm(value, disable);
            break;
          case "array":
            updateForm(value.items, disable);
            if ("ui" in value) {
              if ("widgetConfig" in value.ui) {
                value.ui.widgetConfig.disableAdd = disable;
                value.ui.widgetConfig.disableDel = disable;
                value.ui.widgetConfig.disableReorder = disable;
              } else {
                value.ui.widgetConfig = {
                  disableAdd: disable,
                  disableDel: disable,
                  disableReorder: disable,
                };
              }
            } else {
              value.ui = {
                widgetConfig: {
                  disableAdd: disable,
                  disableDel: disable,
                  disableReorder: disable,
                },
              };
            }
            break;
          default:
            if ("ui" in value) {
              value.ui.disabled = disable;
            } else {
              value.ui = { disabled: disable };
            }
            break;
        }
      }
      break;
    default:
      break;
  }
};

const buildStateActionRoleMap = (machine, roles) => {
  const states = machine.states || {};
  const result = {};
  Object.keys(states).forEach((state) => {
    const actionRoleMap = {};
    const meta = states[state].meta || {};
    const sRoles = meta.roles || {};
    roles.forEach((role) => {
      if (role in sRoles) {
        const actions = sRoles[role];
        actions.forEach((action) => {
          actionRoleMap[action] = actionRoleMap[action] || role;
        });
      }
    });
    // if user has atleast one applicable role  in this workflow
    // and there are actions allowed for any role
    if ("any" in sRoles && roles.length > 0) {
      const actions = sRoles["any"];
      actions.forEach((action) => {
        actionRoleMap[action] = actionRoleMap[action] || roles[0];
      });
    }
    result[state] = actionRoleMap;
  });
  return result;
};

const transitionFn = (state, context) => {
  const armap = context.getters.actionRoleMapByState(state.value);
  const formAllowed = "FORM_SUBMIT" in armap;
  console.log("state transitioned to:", state.value);
  console.log("armap:", JSON.stringify(armap));
  console.log("formAllowed:", formAllowed);
  for (var f in context.state.forms) {
    // update state and user role in the form
    const role = Object.values(armap)[0];
    context.commit("updateFormRole", {
      name: f,
      role: role,
      st: state.value,
    });
    console.log(
      "updated form: ",
      f,
      " role:",
      context.state.forms[f].globalConfig
    );
  }
};

const localTime = (utcTime) => {
  return moment
    .utc(utcTime, "DD MMM YYYY HH:mm:ss")
    .local()
    .calendar({ sameElse: "DD MMM YYYY HH:mm:ss" });
};

const titleCase = (str) => {
  return str
    .toLowerCase()
    .split(" ")
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
};

const untilTimeMs = (target, creationTime) => {
  const d = moment.duration(target);
  const c = moment.utc(creationTime, "DD MMM YYYY HH:mm:ss").local();
  const e = moment.utc(creationTime, "DD MMM YYYY HH:mm:ss").local().add(d);
  const n = moment();
  if (e.isBefore(n)) {
    return 1;
  }
  console.log(
    "d:",
    d.toISOString(),
    "c:",
    c.toDate(),
    "e:",
    e.toDate(),
    "n:",
    n.toDate()
  );
  const u = moment.duration(e.diff(n));
  console.log("u:", u.toISOString());
  return u.asMilliseconds();
};

const startAfterTrigger = ({ target, event, state }, context) => {
  const c = context.state.workFlowInstanceMeta.creation_time;
  if (c === "") {
    return;
  }
  const d = untilTimeMs(target, c);
  console.log(
    "starting after trigger for target:",
    target,
    " event:",
    event,
    " state:",
    state,
    " duration:",
    d
  );
  const s = setTimeout(() => {
    try {
      const cs = context.getters.currentState;
      const role = context.getters.currentRoles;
      if (cs !== state) {
        console.log("ignoring event:", event, " in state:", cs, "on timeout");
        return;
      }
      const fn = context.getters.currentFormName;
      const fd = context.getters.formData(fn);
      context.state.machine.send({
        type: event,
        data: fd,
        API: new glAPI(),
        gData: context.getters.globalData,
        roles: context.getters.userRoles,
        wid: context.getters.getWorkFlowID,
        sign: context.getters.getUserDetails.signature,
        user_id: context.getters.getUserDetails.uuid,
      });
      context.state.authFlowObject
        .postReq("/workflow/" + context.state.workFlowID + "/action", {
          state: cs,
          action: event,
          role: role,
          seq_num: context.state.workFlowInstanceMeta.seq_num + 1,
          persisted_state: context.state.machine.state,
        })
        .then(() => {
          context.commit("newTransaction");
        })
        .catch((reason) => {
          console.log("failed to record time based event:", reason);
        });
    } catch (error) {
      console.log("failed to handle timeout event:", error);
    }
  }, d);
  context.commit("addTimer", s);
};

const checkForAfterTriggers = (context, machine) => {
  const states = machine.states || {};
  console.log("states:", states);
  Object.keys(states).forEach((state) => {
    const meta = states[state].meta || {};
    console.log("state:", state, " meta:", meta);
    if ("after" in meta) {
      meta["after"].forEach((a) => {
        startAfterTrigger(a, context);
      });
    }
  });
};

const restoreFormConstants = (context, cache) => {
  for (var f in context.state.forms) {
    const data = cache[f] || {};
    Object.keys(data).forEach((key) => {
      const value = data[key];
      context.commit("updateFormConstant", {
        form: f,
        name: key,
        value: value,
      });
    });
    console.log(
      "updated form: ",
      f,
      " constants:",
      get(context.state.forms[f], "globalConfig.constants")
    );
  }
};

const notifyOutcome = (context, success) => {
  const msg = context.getters.outcomeMsg(success);
  const cst = titleCase(context.getters.currentStateDisplayName);
  if (success) {
    if (cst != "Unknown") {
      Toast.open({
        duration: 4000,
        queue: true,
        message:
          "<p class='has-text-left'>" +
          msg +
          "</p><p class='has-text-left'>Process is now in <b>" +
          cst +
          "</b> state</p>",
        position: "is-bottom-right",
        type: "is-success",
      });
    } else {
      Toast.open({
        duration: 4000,
        queue: true,
        message: "<p class='has-text-left'>" + msg + "</p>",
        position: "is-bottom-right",
        type: "is-success",
      });
    }
  } else {
    Toast.open({
      duration: 4000,
      queue: true,
      message: msg,
      position: "is-bottom-right",
      type: "is-danger",
    });
  }
  context.commit("successMsg", "");
};

export default new Vuex.Store({
  state: {
    token: "",
    socketMsg: null,
    isRefresh: false,
    templateViewStyle: "grid",
    floKey: "",
    documentDetails: {
      docName: "",
      docId: "",
      docHash: "",
      docHashAlgo: "",
      applicableRole: "",
      docState: "",
    },
    userDetails: {},
    allowedActionList: [],
    allowedActionState: {}, // Allowed action object
    verbList: [],
    templateList: {},
    userFullName: "",
    workFlowID: "",
    workFlowInstanceMeta: {
      seq_num: 0,
    },
    workFlow: {},
    workFlowOptions: {},
    machine: {
      state: {
        actions: [{}],
        context: {
          form: "",
        },
      },
    },
    forms: {},
    formData: {},
    userRoles: [],
    userWorkFlows: [],
    workFlowStucture: {},
    transactions: [],
    authFlowObject: null,
    centrifugoObject: {},
    stateActionRoleMap: {},
    socketSubscription: null,
    userSubscription: null,
    skipAction: false,
    isLoading: false,
    glBusy: false,
    timers: [],
    globalData: {},
    sessionID: "",
    usStateData: [],
    countryList: [],
    subFoldersItem: {
      currentSeletedItem: "",
      data: [],
    },
    history: {
      itemName: "",
      itemId: "",
      transactions: [],
    },
    workFlowInfo: {
      workflowId: "",
      meta: {},
      lastTransaction: {},
      transactions: [],
      roles: [],
      currentState: "",
      query: [],
      display_name: "",
      bundle_name: "",
      name: "",
      author: "",
      current_state: "",
      nearest_timeout: "",
      entry_time: "",
      form_name: "",
      version: "",
      type: "",
    },
    reportDetails: {
      reportData: [],
      columnDefination: {},
    },
    workFlowDetails: null,
    tokens: [],
    profileRefreshEvent: 0,
    currAction: "",
    message: {
      success: "",
      failure: "",
    },
    submitAction: "",
  },
  mutations: {
    // syncronous
    token: (state, payload) => {
      state.token = payload;
      //   console.log(' mutation store payload', state.docType)
    },
    socketMsg: (state, payload) => {
      state.socketMsg = payload;
    },
    isRefresh: (state, payload) => {
      state.isRefresh = payload;
    },
    docDetails: (state, payload) => {
      if (payload) {
        state.documentDetails.docHash = payload.docHash;
        state.documentDetails.docId = payload.docId;
        state.documentDetails.docName = payload.docName;
        state.documentDetails.docHashAlgo = payload.docHashAlgo;
        state.documentDetails.applicableRole = payload.applicableRole;
        state.documentDetails.docState = payload.docState;
      } else {
        state.documentDetails.docHash = "";
        state.documentDetails.docId = "";
        state.documentDetails.docName = "";
        state.documentDetails.docHashAlgo = "";
        state.documentDetails.applicableRole = "";
        state.documentDetails.docState = "";
      }
    },
    userDetails: (state, payload) => {
      state.userDetails = payload;
    },
    userSignature: (state, payload) => {
      state.userDetails.signature = payload;
    },
    floKey: (state, payload) => {
      state.floKey = payload;
    },
    allowedActionList: (state, payload) => {
      state.allowedActionList = payload;
    },
    verbList: (state, payload) => {
      state.verbList = payload;
    },
    allowedActionState: (state, payload) => {
      state.allowedActionState = payload;
    },
    templateList: (state, payload) => {
      state.templateList = payload;
    },
    templateViewStyle: (state, payload) => {
      state.templateViewStyle = payload;
    },
    userFullName: (state, payload) => {
      state.userFullName = payload;
    },
    workFlowID: (state, payload) => {
      state.workFlowID = payload;
    },
    workFlow: (state, payload) => {
      state.workFlow = payload;
    },
    workFlowOptions: (state, payload) => {
      state.workFlowOptions = payload;
    },
    workFlowInstanceMeta: (state, payload) => {
      state.workFlowInstanceMeta = payload;
    },
    machine: (state, payload) => {
      state.machine = payload;
    },
    forms: (state, payload) => {
      state.forms = payload;
    },
    userRoles: (state, payload) => {
      state.userRoles = payload;
    },
    userWorkFlows: (state, payload) => {
      state.userWorkFlows = [...payload];
    },
    workFlowStucture: (state, payload) => {
      state.workFlowStucture = payload;
    },
    authFlowObject: (state, payload) => {
      state.authFlowObject = payload;
    },
    centrifugoObject: (state, payload) => {
      state.centrifugoObject = payload;
    },
    transactions: (state, payload) => {
      state.transactions = [...payload];
    },
    newTransaction: (state) => {
      state.workFlowInstanceMeta.seq_num++;
    },
    formData: (state, { form_name, form_data }) => {
      state.formData[form_name] = form_data;
    },
    resetFormData: (state) => {
      state.formData = {};
    },
    disableForm: (state, { name }) => {
      updateForm(state.forms[name], true);
    },
    enableForm: (state, { name }) => {
      updateForm(state.forms[name], false);
    },
    updateFormRole: (state, { name, role, st }) => {
      let form = state.forms[name];
      set(form, "globalConfig.constants.role", role);
      set(form, "globalConfig.constants.state", st);
    },
    updateFormOrgData: (state, name, data) => {
      let form = state.forms[name];
      set(form, "globalConfig.constants.orgData", data);
    },
    updateFormConstant: (state, { form, name, value }) => {
      let f = state.forms[form];
      set(f, "globalConfig.constants." + name, value);
    },
    stateActionRoleMap: (state, payload) => {
      state.stateActionRoleMap = payload;
    },
    socketSubscription: (state, payload) => {
      state.socketSubscription = payload;
    },
    userSubscription: (state, payload) => {
      state.userSubscription = payload;
    },
    profileRefreshEvent: (state, payload) => {
      state.profileRefreshEvent++;
    },
    addTransaction: (state, payload) => {
      state.transactions.unshift(payload);
      state.transactions = [...state.transactions];
      state.history.transactions.unshift(payload);
      state.history.transactions = [...state.history.transactions];
    },
    addComment: (state, payload) => {
      for (let index = 0; index < state.transactions.length; index++) {
        const element = state.transactions[index];
        if (element.txid == payload.txid) {
          element.comments.push({
            comment: payload.comment,
            user: payload.user,
            timestamp: payload.timestamp,
          });
          break;
        }
      }
      for (let index = 0; index < state.history.transactions.length; index++) {
        const element = state.history.transactions[index];
        if (element.txid == payload.txid) {
          element.comments.push({
            comment: payload.comment,
            user: payload.user,
            timestamp: payload.timestamp,
          });
          break;
        }
      }
    },
    updateSeqNum: (state, payload) => {
      state.workFlowInstanceMeta.seq_num = payload;
    },
    skipAction: (state, payload) => {
      state.skipAction = payload;
    },
    isLoading: (state, payload) => {
      state.isLoading = payload;
    },
    glBusy: (state, payload) => {
      state.glBusy = payload;
    },
    addTimer: (state, payload) => {
      state.timers.push(payload);
    },
    clearTimers: (state) => {
      state.timers.forEach((t) => {
        clearTimeout(t);
      });
      state.timers = [];
    },
    globalData: (state, payload) => {
      state.globalData = {
        ...state.globalData,
        ...payload,
      };
    },
    clearGlobalData: (state) => {
      state.globalData = {};
    },
    sessionID: (state, payload) => {
      state.sessionID = payload;
    },
    usStateData: (state, payload) => {
      state.usStateData = payload;
    },
    countryList: (state, payload) => {
      state.countryList = payload;
    },
    subFoldersItem: (state, payload) => {
      console.log("Sub f Payload", payload);
      state.subFoldersItem.currentSeletedItem = payload.currentSeletedFloder;
      state.subFoldersItem.data = payload.data;
    },
    history: (state, payload) => {
      console.log("History Payload", payload);
      state.history.itemName = payload.name;
      state.history.itemId = payload.it;
      const txns = payload.transactions || [];
      state.history.transactions = txns;
    },
    workFlowInfo: (state, payload) => {
      console.log("INfo data indext store", payload);
      state.workFlowInfo.meta = payload.meta;
      state.workFlowInfo.lastTransaction = payload.lastTransaction;
      state.workFlowInfo.roles = payload.roles;
      state.workFlowInfo.currentState = payload.currentState;
      state.workFlowInfo.query = payload.query;
      state.workFlowInfo.workflowId = payload.workflowId;
      state.workFlowInfo.display_name = payload.display_name;
      state.workFlowInfo.bundle_name = payload.bundle_name;
      state.workFlowInfo.name = payload.name;
      state.workFlowInfo.author = payload.author;
      state.workFlowInfo.current_state = payload.current_state;
      state.workFlowInfo.nearest_timeout = payload.nearest_timeout;
      state.workFlowInfo.entry_time = payload.entry_time;
      state.workFlowInfo.form_name = payload.form_name;
      state.workFlowInfo.version = payload.version;
      state.workFlowInfo.type = payload.type;
      state.workFlowInfo.transactions = payload.transactions;
      state.workFlowInfo.cardid = payload.cardid;
      state.workFlowInfo.processType = payload.processType;
    },
    workFlowDetails: (state, payload) => {
      state.workFlowDetails = payload;
    },
    workFlowQuery: (state, payload) => {
      state.workFlowInfo.query = payload;
    },
    userTokens: (state, payload) => {
      state.tokens = payload;
    },
    reportDetails: (state, payload) => {
      state.reportDetails.reportData = payload.reportData;
      state.reportDetails.columnDefination = payload.columnDefination;
    },
    currAction: (state, payload) => {
      state.currAction = payload;
    },
    successMsg: (state, payload) => {
      state.message.success = payload;
    },
    submitAction: (state, payload) => {
      state.submitAction = payload;
    },
    saveOutcomeMsg: (state, { st, action }) => {
      if (!state.workFlow || !state.workFlow.states) {
        console.log("no workflow selected, using fallback message");
        if (action != "DELETE") {
          if (action == "FORM_SUBMIT") {
            action = state.submitAction || "Submit";
          }
          const sm = "Action <b>" + titleCase(action) + "</b> Successful";
          state.message.success = sm;
        } else {
          const sm = "Instance deleted. Task list / Cards updated.";
          state.message.success = sm;
        }
        if (action == "FORM_SUBMIT") {
          action = state.submitAction || "Submit";
        }
        const fm =
          "Action <b>" + titleCase(action) + "</b> Failed. Please Retry.";
        state.message.failure = fm;
        return;
      }
      const meta = state.workFlow.states[st].meta || {};
      const msgs = meta.messages || {};
      const amsgs = msgs[action] || {};
      console.log(
        "workflow selected, using message for ",
        action,
        " in state ",
        st
      );
      if (action != "DELETE") {
        if (action == "FORM_SUBMIT") {
          action = state.submitAction || "Submit";
        }
        const sm = "Action <b>" + titleCase(action) + "</b> Successful";
        state.message.success = amsgs.success || sm;
      } else {
        const sm = "Instance deleted. Task list / Cards updated.";
        state.message.success = amsgs.success || sm;
      }
      if (action == "FORM_SUBMIT") {
        action = state.submitAction || "Submit";
      }
      const fm =
        "Action <b>" + titleCase(action) + "</b> Failed. Please Retry.";
      state.message.failure = amsgs.failure || fm;
    },
  },
  actions: {
    // asyncronous
    FORM_DATA: (context, payload) => {
      context.commit("formData", payload);
    },
    TOKEN: (context, payload) => {
      //   console.log(' action store payload', payload)
      context.commit("token", payload);
    },
    SOCKER_MSG: (context, payload) => {
      //   console.log(' action store payload', payload)
      context.commit("socketMsg", payload);
    },
    IS_REFRESH: (context, payload) => {
      context.commit("isRefresh", payload);
    },
    DOC_DETAILS: (context, payload) => {
      console.log("Console log : payload", payload);
      context.commit("docDetails", payload);
    },
    async USER_DETAILS(context, payload) {
      context.commit("userDetails", payload);
      if (payload.token !== "") {
        context.getters.centrifugoObject.setToken(payload.token);
        context.getters.centrifugoObject.connect();
        if (payload.uuid !== "") {
          context.getters.centrifugoObject.subscribe(
            "user#" + payload.uuid,
            async (msg) => {
              const data = msg.data || {};
              const type = data.type || "";
              switch (type) {
                case "workflow_refresh":
                  console.log("workflow refresh event for user");
                  await context.dispatch("CLEAR_WORKFLOW");
                  await context.dispatch("FETCH_WORKFLOWS");
                  break;
                case "wallet_refresh":
                  console.log("wallet refresh event for user");
                  await context.dispatch("FETCH_TOKENS");
                  break;
                case "profile_refresh":
                  console.log("profile refresh event for user");
                  context.commit("profileRefreshEvent");
                default:
                  break;
              }
            }
          );
        }
        if (payload.signature.id !== "") {
          let resp = await axios.get("/api/user/signature", null);
          context.commit("userSignature", resp.data.signature_data);
        }
        context.getters.centrifugoObject.subscribe(
          "org:" + payload.user_org,
          async (msg) => {
            const data = msg.data || {};
            const type = data.type || "";
            switch (type) {
              case "gdata_update":
                console.log("global data update");
                context.commit("globalData", data.payload);
                break;
              case "profile_refresh":
                console.log("profile refresh event for org");
                context.commit("profileRefreshEvent");
              default:
                break;
            }
          }
        );
      }
    },
    FLO_KEY: (context, payload) => {
      context.commit("floKey", payload);
    },
    ALLOWED_ACTION_LIST: (context, payload) => {
      context.commit("allowedActionList", payload);
    },
    VERB_LIST: (context, payload) => {
      context.commit("verbList", payload);
    },
    ALLOWED_ACTION_STATE: (context, payload) => {
      context.commit("allowedActionState", payload);
    },
    TEMPLATE_LIST: (context, payload) => {
      context.commit("templateList", payload);
    },
    TEMPLATE_VIEW_STYLE: (context, payload) => {
      context.commit("templateViewStyle", payload);
    },
    USER_FULL_NAME: (context, payload) => {
      context.commit("userFullName", payload);
    },
    async INIT_STATICDATA(context) {
      try {
        let resp = await axios.get("/assets/option-constants.json", null);
        context.commit("usStateData", resp.data.US_STATE_DATA);
        context.commit("countryList", resp.data.COUNTRY_LIST);
      } catch (error) {
        console.log("failed to fetch constansts:", error);
      }
    },
    async USER_LOGOUT(context) {
      context.commit("userWorkFlows", []);
      await context.dispatch("CLEAR_WORKFLOW");
    },
    async CLEAR_WORKFLOW(context) {
      try {
        context.commit("workFlowID", "");
        const subs = context.getters.socketSubscription;
        if (subs) {
          subs.unsubscribe();
          subs.removeAllListeners();
          context.commit("socketSubscription", null);
        }
        const wm = {
          state: {
            actions: [{}],
            context: {
              form: "",
            },
          },
        };
        context.commit("machine", wm);
        context.commit("workFlowInstanceMeta", {
          seq_num: 0,
        });
        context.commit("forms", {});
        context.commit("userRoles", []);
        context.commit("transactions", []);
        context.commit("resetFormData");
        context.commit("stateActionRoleMap", {});
        context.commit("workFlow", {});
        context.commit("workFlowOptions", {});
        context.commit("clearTimers");
        context.commit("clearGlobalData");
      } catch (error) {
        console.log("reset workflow failed:", error);
      }
    },
    async SELECT_WORKFLOW(context, { value }) {
      // fetch workflow definition - forms and state machine and commit the same
      // then commit the workflow id
      let currentValue = context.state.workFlowID;
      try {
        const subs = context.getters.socketSubscription;
        if (subs) {
          subs.unsubscribe();
          subs.removeAllListeners();
          context.commit("socketSubscription", null);
        }
        let instance = await axios.get("/api/workflow/" + value, {});
        let definition = await axios.get(
          "/api/workflow/" + value + "/definition",
          {}
        );
        context.commit("workFlowInstanceMeta", instance.data.meta);

        context.commit("forms", definition.data.forms);
        context.commit("userRoles", instance.data.roles);
        const txns = instance.data.transactions || [];
        context.commit("transactions", txns.reverse());
        context.commit("resetFormData");
        for (var f in instance.data.form_data) {
          context.commit("formData", {
            form_name: f,
            form_data: instance.data.form_data[f],
          });
        }
        restoreFormConstants(context, instance.data.process_cache);
        const sarmap = buildStateActionRoleMap(
          definition.data.workflow,
          instance.data.roles
        );
        console.log("sarmap:", sarmap);
        context.commit("stateActionRoleMap", sarmap);
        let wo = definition.data.options;
        for (var gu in wo.guards) {
          wo.guards[gu] = new Function(
            "context",
            "event",
            "condMeta",
            wo.guards[gu]
          );
        }
        for (var a in wo.actions) {
          if (typeof wo.actions[a] === "object") {
            continue;
          }
          wo.actions[a] = new Function(
            "context",
            "event",
            "actionMeta",
            wo.actions[a]
          );
        }
        console.log("after transform:", wo);
        context.commit("workFlow", definition.data.workflow);
        context.commit("workFlowOptions", wo);
        context.commit("clearGlobalData");
        context.commit("globalData", instance.data.global_data);
        let w = createMachine(definition.data.workflow, wo);
        var ps = instance.data.state || w.initialState;
        var st = State.create(ps);
        var rs;
        try {
          rs = w.resolveState(st);
        } catch (error) {
          console.log(
            "resolve state failed, resttting to initial state:",
            error
          );
          ps = w.initialState;
          st = State.create(ps);
          rs = w.resolveState(st);
        }
        delete rs.actions; // hack due to https://github.com/statelyai/xstate/discussions/1757
        let wm = interpret(w)
          .start(rs)
          .onTransition((state) => {
            transitionFn(state, context);
          });
        context.commit("machine", wm);
        if (wm.state.done) {
          transitionFn(wm.state, context);
        }
        context.commit("clearTimers");
        // checkForAfterTriggers(context, definition.data.workflow);
        const newSubs = context.getters.centrifugoObject.subscribe(
          "workflow:" + value,
          async (msg) => {
            const data = msg.data || {};
            const type = data.type || "";
            switch (type) {
              case "comment":
                console.log("new comment event");
                context.commit("addComment", data.payload);
                break;
              case "transaction":
                console.log("new transaction event");
                context.commit("addTransaction", data.payload);
                break;
              case "xstate":
                console.log("new xstate event");
                if (data.payload.action === "Form Submit") {
                  context.commit("formData", {
                    form_name: data.payload.form_name,
                    form_data: data.payload.form_data,
                  });
                }
                if (data.payload.seq_num != context.getters.currentSeqNum) {
                  let w = createMachine(
                    context.getters.workFlow,
                    context.getters.workFlowOptions
                  );
                  var ps = data.payload.persisted_state || w.initialState;
                  var st = State.create(ps);
                  var rs = w.resolveState(st);
                  delete rs.actions; // hack due to https://github.com/statelyai/xstate/discussions/1757
                  let wm = interpret(w)
                    .start(rs)
                    .onTransition((state) => {
                      transitionFn(state, context);
                    });
                  context.commit("machine", wm);
                  context.commit("updateSeqNum", data.payload.seq_num);
                  const fn = context.getters.currentFormName;
                  const fd = context.getters.formData(fn);
                  context.state.machine.send({
                    type: "GL_RESTORED",
                    data: fd,
                    API: new glAPI(),
                    gData: context.getters.globalData,
                    roles: context.getters.userRoles,
                    wid: context.getters.getWorkFlowID,
                    sign: context.getters.getUserDetails.signature,
                    user_id: context.getters.getUserDetails.uuid,
                  });
                }
                break;
              case "bundle_update":
                context.commit("resetFormData");
                context.commit("clearTimers");
                await context.dispatch("SELECT_WORKFLOW", {
                  value: context.getters.getWorkFlowID,
                });
                console.log("bundle update recvd, workflow reselected");
                context.commit("isLoading", false);
                break;
              default:
                console.log("unexpected socket msg:", data);
                break;
            }
          }
        );
        console.log("newSubs", newSubs);
        context.commit("socketSubscription", newSubs);
        context.commit("sessionID", globalFunction.generateId());
        const fn = context.getters.currentFormName;
        const fd = context.getters.formData(fn);
        context.state.machine.send({
          type: "GL_RESTORED",
          data: fd,
          API: new glAPI(),
          gData: context.getters.globalData,
          roles: context.getters.userRoles,
          wid: value,
          sign: context.getters.getUserDetails.signature,
          user_id: context.getters.getUserDetails.uuid,
        });
        context.commit("workFlowID", value);
      } catch (error) {
        console.log("failed to fetch workflow details: ", error);
        globalFunction.error(
          "Failed to load workflow, please choose a different workflow"
        );
        await context.dispatch("CLEAR_WORKFLOW");
      }
    },
    async REFRESH_QUERY(context) {
      try {
        let resp = await axios.get(
          "/api/workflow/" + context.getters.getWorkFlowID + "/query",
          {}
        );
        context.commit("workFlowQuery", resp.data);
      } catch (error) {
        console.log("failed to refresh workflow query:", error);
      }
    },
    async FETCH_WORKFLOWS(context) {
      context.commit("userWorkFlows", []);
      context.commit("workFlowStucture", []);
      try {
        let flows = await axios.get("/api/user/workflows", {});
        context.commit("userWorkFlows", flows.data);
        let tasks = await axios.get("/api/user/tasks");
        // console.log("tasks", JSON.stringify(tasks.data));
        context.commit("workFlowStucture", tasks.data);
      } catch (error) {
        // context.commit("userWorkFlows", []);
      }
    },
    async FETCH_TOKENS(context) {
      try {
        let cards = [];
        let resp = await axios.get("/api/user/tokens");
        console.log("token resp:", resp.data);
        for (const id in resp.data) {
          const d = resp.data[id];
          cards.push({
            id: id,
            workflow_id: d.workflow_id,
            current_state: d.current_state,
            workflow_name: d.workflow_name,
            invalidated: d.invalidated,
            is_owner: d.is_owner,
            shared_by: d.shared_by,
          });
        }
        context.commit("userTokens", cards);
      } catch (error) {
        console.log("user token error:", error);
      }
    },
    async INIT_AUTHOBJECT(context) {
      try {
        let ao = new AuthFlow();
        context.commit("authFlowObject", ao);
      } catch (error) {
        console.log("failed to init auth object:", error);
      }
    },
    async INIT_CENTRIFUGO(context, { isProduction }) {
      let cf = null;
      if (isProduction) {
        cf = new Centrifuge("wss://flows.qbrics.com/connection/websocket", {
          debug: true,
        });
      } else {
        cf = new Centrifuge("wss://flows.qbrics.com/connection/websocket", {
          debug: true,
        });
      }
      cf.on("unsubscribe", function (ctx) {
        console.log("Unsubscribe from server-side channel " + ctx.channel);
      });
      cf.on("connect", (ctx) => {
        console.log("on connect: ", ctx);
      });
      cf.on("disconnect", (ctx) => {
        console.log("on disconnect: ", ctx);
      });
      context.commit("centrifugoObject", cf);
    },
    async SUBMIT_FORM(context, { data }) {
      try {
        console.log("form submit:", data);
        context.commit("isLoading", true);
        context.commit("currAction", "FORM_SUBMIT");
        const fn = context.state.machine.state.context.form;
        const cs = context.getters.currentState;
        const armap = context.getters.actionRoleMapByState(cs);
        context.commit("formData", { form_name: fn, form_data: data });
        context.commit("saveOutcomeMsg", { st: cs, action: "FORM_SUBMIT" });
        context.state.machine.send({
          type: "FORM_SUBMIT",
          data: data,
          API: new glAPI(),
          gData: context.getters.globalData,
          roles: context.getters.userRoles,
          wid: context.getters.getWorkFlowID,
          sign: context.getters.getUserDetails.signature,
          user_id: context.getters.getUserDetails.uuid,
        });
        if (!context.state.skipAction) {
          const action = context.getters.submitAction || "Submit " + fn;
          let resp = await context.state.authFlowObject.postReq(
            "/workflow/" + context.state.workFlowID + "/action",
            {
              state: cs,
              action: action,
              role: armap["FORM_SUBMIT"],
              seq_num: context.state.workFlowInstanceMeta.seq_num + 1,
              persisted_state: context.state.machine.state,
              form_data: data,
              form_name: fn,
            }
          );
          if (resp.status == 200) {
            notifyOutcome(context, true);
          } else {
            notifyOutcome(context, false);
          }
          context.commit("newTransaction");
        } else if (!context.state.glBusy) {
          notifyOutcome(context, true);
        }
        context.commit("skipAction", false);
        context.commit("submitAction", "");
        await context.dispatch("FETCH_WORKFLOWS");
        await context.dispatch("REFRESH_QUERY");
        context.commit("isLoading", false);
      } catch (error) {
        console.log("failed to submit form data:", error);
        context.commit("skipAction", false);
        context.commit("isLoading", false);
        notifyOutcome(context, false);
      }
    },
    async USER_ACTION(context, { action }) {
      try {
        context.commit("isLoading", true);
        context.commit("currAction", action);
        const cs = context.getters.currentState;
        context.commit("saveOutcomeMsg", { st: cs, action: action });
        const armap = context.getters.actionRoleMapByState(cs);
        const fn = context.getters.currentFormName;
        const fd = context.getters.formData(fn);
        context.state.machine.send({
          type: action,
          data: fd,
          API: new glAPI(),
          gData: context.getters.globalData,
          roles: context.getters.userRoles,
          wid: context.getters.getWorkFlowID,
          sign: context.getters.getUserDetails.signature,
          user_id: context.getters.getUserDetails.uuid,
        });
        if (!context.state.skipAction) {
          let resp = await context.state.authFlowObject.postReq(
            "/workflow/" + context.state.workFlowID + "/action",
            {
              state: cs,
              action: action,
              role: armap[action],
              seq_num: context.state.workFlowInstanceMeta.seq_num + 1,
              persisted_state: context.state.machine.state,
            }
          );
          if (resp.status == 200) {
            notifyOutcome(context, true);
          } else {
            notifyOutcome(context, false);
          }
          context.commit("newTransaction");
        } else if (!context.state.glBusy) {
          notifyOutcome(context, true);
        }
        context.commit("skipAction", false);
        await context.dispatch("FETCH_WORKFLOWS");
        await context.dispatch("REFRESH_QUERY");
        context.commit("isLoading", false);
      } catch (error) {
        console.log("failed to submit user action:", error);
        context.commit("skipAction", false);
        context.commit("isLoading", false);
        notifyOutcome(context, false);
      }
    },
    WORKFLOW_SUBFOLDER(context, { currentSeletedFloder, data }) {
      console.log("Instore data", currentSeletedFloder, data);
      context.commit("subFoldersItem", { currentSeletedFloder, data });
    },
    async FETCH_HISTORY(context, { value, cardid, processType }) {
      // fetch workflow definition - forms and state machine and commit the same
      // then commit the workflow id
      console.log("IN FETCH_HISTORY", value, cardid, processType);
      let currentValue = context.state.workFlowID;
      try {
        const subs = context.getters.socketSubscription;
        if (subs) {
          subs.unsubscribe();
          subs.removeAllListeners();
          context.commit("socketSubscription", null);
        }
        let instance = await axios.get("/api/workflow/" + value, {});
        console.log("All Data", instance.data);
        context.commit("workFlowInfo", {
          meta: instance.data.meta,
          roles: instance.data.roles,
          lastTransaction: instance.data.last_transaction,
          currentState: instance.data.state ? instance.data.state.value : "",
          query: instance.data.query_result,
          workflowId: instance.data.id,
          display_name: instance.data.display_name,
          bundle_name: instance.data.bundle_name,
          name: instance.data.name,
          author: instance.data.author,
          current_state: instance.data.current_state,
          nearest_timeout: instance.data.nearest_timeout,
          form_name: instance.data.form_name,
          entry_time: instance.data.entry_time,
          version: instance.data.version,
          transactions: instance.data.transactions,
          type: instance.data.type,
          cardid: cardid,
          processType: processType,
        });
        context.commit("history", instance.data);
        const newSubs = context.getters.centrifugoObject.subscribe(
          "workflow:" + value,
          async (msg) => {
            const data = msg.data || {};
            const type = data.type || "";
            switch (type) {
              case "comment":
                console.log("new comment event");
                context.commit("addComment", data.payload);
                break;
              case "transaction":
                console.log("new transaction event");
                context.commit("addTransaction", data.payload);
                break;
              case "xstate":
                console.log("new xstate event");
                if (data.payload.action === "Form Submit") {
                  context.commit("formData", {
                    form_name: data.payload.form_name,
                    form_data: data.payload.form_data,
                  });
                }
                if (data.payload.seq_num != context.getters.currentSeqNum) {
                  let w = createMachine(
                    context.getters.workFlow,
                    context.getters.workFlowOptions
                  );
                  var ps = data.payload.persisted_state || w.initialState;
                  var st = State.create(ps);
                  var rs = w.resolveState(st);
                  delete rs.actions; // hack due to https://github.com/statelyai/xstate/discussions/1757
                  let wm = interpret(w)
                    .start(rs)
                    .onTransition((state) => {
                      transitionFn(state, context);
                    });
                  context.commit("machine", wm);
                  context.commit("updateSeqNum", data.payload.seq_num);
                  const fn = context.getters.currentFormName;
                  const fd = context.getters.formData(fn);
                  context.state.machine.send({
                    type: "GL_RESTORED",
                    data: fd,
                    API: new glAPI(),
                    gData: context.getters.globalData,
                    roles: context.getters.userRoles,
                    wid: context.getters.getWorkFlowID,
                    sign: context.getters.getUserDetails.signature,
                    user_id: context.getters.getUserDetails.uuid,
                  });
                }
                break;
              case "bundle_update":
                context.commit("resetFormData");
                context.commit("clearTimers");
                await context.dispatch("SELECT_WORKFLOW", {
                  value: context.getters.getWorkFlowID,
                });
                console.log("bundle update recvd, workflow reselected");
                context.commit("isLoading", false);
                break;
              default:
                console.log("unexpected socket msg:", data);
                break;
            }
          }
        );
        console.log("newSubs", newSubs);
        context.commit("socketSubscription", newSubs);
      } catch (error) {
        console.log("failed to fetch workflow details: ", error);
      }
    },
    async FETCH_DETAILS(context, { itemid, itemName }) {
      let details = await axios.get(
        "/api/template/" + itemName + "/details",
        {}
      );
      console.log("Details API", details);
      context.commit("workFlowDetails", details);
    },
    async FETCH_REPORT_DETAILS(context, { workflowid }) {
      let report_details = await axios.get("/api/report/" + workflowid);
      console.log("reports data", report_details);
      let column_defination = await axios.get(
        "/api/report/" + workflowid + "/definition"
      );
      console.log("column data", column_defination);
      context.commit("reportDetails", {
        reportData: report_details,
        columnDefination: column_defination,
      });
    },
    async SEND_COMMENT(context, { txid, workFlowID, comment }) {
      let result = await axios.post(
        "/api/workflow/" + workFlowID + "/comment",
        {
          txid: txid,
          comment: comment,
        }
      );
      if (result.status != 200) {
        console.log("Error while sending comment!");
      } else {
        console.log("Successfully send the comment!");
      }
    },
    async NOTIFY_OUTCOME(context, success) {
      notifyOutcome(context, success);
    },
  },
  getters: {
    getToken(state) {
      return state.token;
    },
    getWorkFlowDetails: (state) => state.workFlowDetails,
    getSoketMsg: (state) => state.token,
    getIsRefresh: (state) => state.isRefresh,
    getDocDetails: (state) => state.documentDetails,
    getUserDetails: (state) => state.userDetails,
    getFloKey: (state) => state.floKey,
    getAllowedActionList: (state) => state.allowedActionList,
    getVerbList: (state) => state.verbList,
    getAllowedActionState: (state) => state.allowedActionState,
    getTemplateList: (state) => state.templateList,
    getTemplateViewStyle: (state) => state.templateViewStyle,
    getUserFullName: (state) => state.userFullName,
    getWorkFlowID: (state) => state.workFlowID,
    socketSubscription: (state) => state.socketSubscription,
    currentSeqNum: (state) => state.workFlowInstanceMeta.seq_num,
    workFlowOptions: (state) => state.workFlowOptions,
    workFlow: (state) => state.workFlow,
    machine: (state) => state.machine,
    isLoading: (state) => state.isLoading || state.glBusy,
    glBusy: (state) => state.glBusy,
    globalData: (state) => state.globalData,
    sessionID: (state) => state.sessionID,
    usStateData: (state) => state.usStateData,
    countryList: (state) => state.countryList,
    userRoles: (state) => state.userRoles,
    userTokens: (state) => state.tokens,
    profileRefreshEvent: (state) => state.profileRefreshEvent,
    workFlowStucture: (state) => state.workFlowStucture,
    currAction: (state) => state.currAction,
    successMsg: (state) => state.successMsg,
    submitAction: (state) => state.submitAction,
    actions: (state) =>
      state.workFlowStucture.tasks ? state.workFlowStucture.tasks.actions : {},
    pendings: (state) =>
      state.workFlowStucture.tasks ? state.workFlowStucture.tasks.pending : [],
    archived: (state) =>
      state.workFlowStucture.tasks
        ? state.workFlowStucture.tasks.completed
        : [],
    availables: (state) =>
      state.workFlowStucture.tasks
        ? state.workFlowStucture.tasks.available
        : [],
    bundles: (state) =>
      state.workFlowStucture.tasks ? state.workFlowStucture.tasks.bundles : [],
    stats: (state) =>
      state.workFlowStucture.tasks
        ? state.workFlowStucture.tasks.stats
        : {
            num_urgent: "NA",
            num_dormant: "NA",
            num_complete: "NA",
            num_launched: "NA",
            wallet_balance: "NA",
          },
    currentState(state) {
      if (!state.machine) {
        return "";
      }
      if (!state.machine.state) {
        return "";
      }
      return state.machine.state.value;
    },
    currentStateDisplayName(state) {
      if (!state.machine || !state.machine.state) {
        return "Unknown";
      }
      if (!state.workFlow || !state.workFlow.states) {
        return "Unknown";
      }
      const cs = state.machine.state.value;
      const meta = state.workFlow.states[cs].meta || {};
      const dn = meta.display_name || cs;
      return dn;
    },
    outcomeMsg: (state) => (success) => {
      return success ? state.message.success : state.message.failure;
    },
    workFlowInitiator(state) {
      return state.workFlowInstanceMeta.initiator || "";
    },
    currentRoles(state) {
      const cs = state.machine.state.value;
      const actions = state.stateActionRoleMap[cs] || {};
      const roles = Object.values(actions);
      return [...new Set(roles)].join(",");
    },
    lastTransaction(state) {
      if (state.transactions.length > 0) {
        const txn = state.transactions[0];
        return (
          txn.txid.slice(0, 5) +
          "..." +
          txn.txid.slice(-5) +
          " by " +
          txn.user +
          " @ " +
          localTime(txn.time)
        );
      }
      return "";
    },
    userWorkFlows(state) {
      return state.userWorkFlows;
    },
    authFlowObject(state) {
      return state.authFlowObject;
    },
    centrifugoObject(state) {
      return state.centrifugoObject;
    },
    transactions(state) {
      return state.transactions;
    },
    allowedActionList(state) {
      // return the actions part of current state allowed for current role
      let cs = state.machine.state.value;
      const actions = state.stateActionRoleMap[cs] || {};
      return Object.keys(actions).filter((ev) => ev !== "FORM_SUBMIT");
    },
    currentForm(state) {
      let cs = state.machine.state;
      let fn = cs.context.form;
      return state.forms[fn] || null;
    },
    currentFormName(state) {
      let cs = state.machine.state;
      let fn = cs.context.form || "";
      return fn;
    },
    formData: (state) => (name) => {
      return state.formData[name] || null;
    },
    formSchema(state) {
      return state.forms || null;
    },
    formSchemaByName: (state) => (name) => {
      return state.forms[name] || null;
    },
    actionRoleMapByState: (state) => (st) => {
      return state.stateActionRoleMap[st] || {};
    },
    formSubmitAllowed(state) {
      const cs = state.machine.state.value;
      const armap = state.stateActionRoleMap[cs] || {};
      return "FORM_SUBMIT" in armap;
    },
    getsubFoldersItem(state) {
      console.log("sub in strore", state.history);
      return state.subFoldersItem;
    },
    getHistory(state) {
      console.log("history in strore", state.history);
      return state.history;
    },
    getWorkFlowInfo(state) {
      return state.workFlowInfo;
    },
    getReportDetails(state) {
      return state.reportDetails;
    },
  },
});
