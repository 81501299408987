<template>
    <div class="linear-container">
        <el-checkbox v-model="modelVal" @change="checkSelection" :disabled="disabled">{{
            _analyzeVal($parent.schema.ui.label)
        }}</el-checkbox>
        <el-tooltip
            class="item linear-container-popper"
            effect="dark"
            placement="right-start">
            <div slot="content" v-html="$parent.schema.ui.help.content"></div>
            <a class="help" v-if="$parent.schema.ui.help.show === true" href="#">
                <span :class="$parent.schema.ui.help.iconCls">{{
                    $parent.schema.ui.help.text
                }}</span>
            </a>
        </el-tooltip>
    </div>
</template>

<style>
.linear-container {
    display: flex;
    align-items: center;
    height: 100%;
}
.linear-container-popper {
    margin-left: 10px !important;
}
</style>

<script>
import ncformCommon from "@ncform/ncform-common";

export default {
    name: "I9BooleanAttest",
    mixins: [
        ncformCommon.mixins.vue.controlMixin,
        ncformCommon.mixins.vue.layoutObjectMixin,
    ],
    props: {
        value: {
            type: Boolean,
            default: false,
        },
    },
    created() {
        if (this.value) {
            this.modelVal = this.value;
        } else if (this.mergeConfig.default) {
            this.modelVal = true;
        }
    },
    data() {
        return {
            defaultConfig: {
                otherChecked: false,
                default: false,
            },
        };
    },
    methods: {
        checkSelection(current) {
            // Prevents unselection like a radio button so that the other dependent value is checked
            if (!current && !this.mergeConfig.otherChecked) {
                this.modelVal = true;
            }
        }
    },
    watch: {
        "mergeConfig.otherChecked": {
            handler: function (n) {
                if (n) {
                    this.modelVal = false;
                }
            },
            immediate: true,
        },
    },
};
</script>
