const endpoint = {
  USER_REG: "user/register",
  USER_DETAILS: "user/details",
  USER_PROFILE: "user/profile",
  USER_WET_SIGN: "user/signature",
  USER_FEEDBACK: "user/feedback",
  USER_ACTION: "user/actions",
  USER_DOCS: "/user/documents",

  DOC_ASSIGN: "document/assign",
  DOC_ASSERT: "document/assert",
  DOC_SIGN: "document/sign",
  DOC_REG: "document/register",
  DOC_FINALIZE: "document/finalize",
  DOC_ACCEPT: "document/accept",
  DOC_VERSION: "document/link",
  DOC_PROFILE: "/document/profile",
  DOC_TEMP: "document/template",
  DOC_TEMP_PERM_PROFILE: "/document/template/profile",
  DOC_PUSH: "/document/push",
  DOC_PULL: "/document/pull",
  DOC_FORK: "/document/template/fork",
  DOC_ACTION_DIS: "/document/action/dismiss",
};

const globals = {
  TOKEN_ID: "",
  current_file_hash: "",
  current_file: "", // this is the full path with the file
  current_file_id: "",
  current_file_name: "",
  authFlowObject: null,
  centrifugo: null,
  docSubToken: "",
  clientID: "",
  allActions: null,
};

const config = {
  showDetails: true,
};

var docProfile = {
  name: "", // no need
  consensus: [
    {
      action: "",
      consensus: "",
    },
  ],
  roles: [
    {
      name: "",
      base: "",
      groups: [],
      exclusions: [],
      actions: [],
      consensus: {
        internal: "",
        external: 0,
      },
    },
  ],
};

export { endpoint, globals, config, docProfile };
