<template>
    <div>
        <el-select
            v-if="!mergeConfig.force"
            v-model="modelVal"
            placeholder="Select a value"
            size="large"
            :disabled="mergeConfig.disabled"
            :clearable="false"
            :multiple="false"
            :filterable="false"
        >
            <el-option :key="1" value="N/A"></el-option>
            <el-option :key="2" value="Alien Number"></el-option>
            <el-option :key="3" value="USCIS Number"></el-option>
        </el-select>
        <el-select
            v-if="mergeConfig.force"
            v-model="modelVal"
            placeholder="Select a value"
            size="large"
            :disabled="mergeConfig.disabled"
            :clearable="false"
            :multiple="false"
            :filterable="false"
        >
            <el-option :key="2" value="Alien Number"></el-option>
            <el-option :key="3" value="USCIS Number"></el-option>
        </el-select>
    </div>
</template>

<script>
import ncformCommon from "@ncform/ncform-common";

export default {
    name: "I9NumberTypeAttest",
    mixins: [ncformCommon.mixins.vue.controlMixin],
    props: {
        value: {
            type: String,
            default: null,
        },
    },
    created() {
        if (this.value) {
            this.modelVal = this.value;
        }
    },
    data() {
        return {
            defaultConfig: {
                otherChecked: false,
                force: false,
            },
        };
    },
    watch: {
        "mergeConfig.otherChecked": {
            handler: function (n) {
                if (n) {
                    this.modelVal = "N/A";
                }                
            },
            immediate: true,
        },
        "mergeConfig.force": {
            handler: function(n) {
                if (n) {
                    this.modelVal = "Alien Number";
                }
            },
            immediate: true,
        },
    },
};
</script>
