<template>
    <el-input v-model="inputVal" :readonly="readonly" :placeholder="placeholder" :disabled="disabled"
        :clearable="mergeConfig.clearable" v-show="!hidden" @blur="onBlur"></el-input>
</template>

<script>
import ncformCommon from "@ncform/ncform-common";

export default {
    name: "ConditionalString",
    mixins: [
        ncformCommon.mixins.vue.controlMixin,
        ncformCommon.mixins.vue.layoutObjectMixin,
    ],
    props: {
        value: {
            type: String,
            default: null,
        },
    },
    created() {
        if (this.$data.modelVal !== undefined) {
            this.$data.inputVal = this.$data.modelVal;
        }
    },
    data() {
        return {
            defaultConfig: {
                condition: false,
                falsy: ""
            },
            inputVal: "",
        };
    },
    methods: {
        _processModelVal(newVal) {
            let val;
            if (newVal !== undefined) {
                val = newVal;
            } else {
                val = this.$data.inputVal;
            }
            this.$data.inputVal = val;
            return val;
        },

        onBlur() {
            let val = this._processModelVal();
            this.$emit("input", val);
        }
    },    
    watch: {
        "mergeConfig.condition": {
            handler: function (n) {                
                if (n) {                    
                    this.$emit("input",this.mergeConfig.falsy);
                }
            },
            immediate: true,
        },
    },
};
</script>
